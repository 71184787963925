import React from 'react';
import styled from '@emotion/styled';
import {useTranslation} from "react-i18next";

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: #f0f8ff;
`;

const HomeTitle = styled.h1`
  font-size: 2rem;
  text-align: center;
  color: #FF6347;
  margin-bottom: 1.5rem;
  font-family: 'Comic Neue', cursive, sans-serif;
  padding-top: inherit;
`;

const HomeText = styled.p`
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.6;
  color: #4B0082;
  font-family: 'Comic Neue', cursive, sans-serif;
`;

const Home: React.FC = () => {
    const { t } = useTranslation();
    const homeText = t('homeText') || '';

    return (
        <HomeContainer>
            <HomeTitle>{t('homeTitle')}</HomeTitle>
            <HomeText dangerouslySetInnerHTML={{ __html: homeText }} />
        </HomeContainer>
    );
};

export default Home;
