// localStorageUtils.ts
export function incrementCredit(kind: string, mediaType:string): void {
    // Get the item from localStorage
    let item = localStorage.getItem('credit');
    // Parse the item to an object
    let itemObject = item ? JSON.parse(item) : {};
    // Increment the challenges_number
    if (kind === "riddle") {
        itemObject.challenges_number++;
    } else if (mediaType === "audio") {
        itemObject.audio_story_number++;
    } else if (mediaType === "video") {
        itemObject.video_story_number++;
    }
    // Save the object back to localStorage
    localStorage.setItem('credit', JSON.stringify(itemObject));
}

export function checkDisableButton(kind: string, mediaType:string) {
    const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
    const credit = JSON.parse(localStorage.getItem('credit') || '{}');
    // Extract plan and mediaType, kind from your state or props
    const plan = userInfo.plan;
    if(kind === 'riddle' && credit.challenges_number >= plan.challenges_number) {
        return true;
    }
    if(kind === 'story') {
        if(mediaType === 'audio' && credit.audio_story_number >= plan.audio_story_number) {
            return true;
        }
        if(mediaType === 'video' && credit.video_story_number >= plan.video_story_number) {
            return true;
        }
    }
    return false;
}
