import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import React from "react";

const resources = {
    en: {
        translation: {
            "story": "a story",
            "riddle": "a riddle",
            "joke": "a joke",
            "mediaSource": "To request {{kind}}",
            "useKeyboard": "I type on Keyboard",
            "useMicrophone": "I speak to Microphone",
            "usedrawing": "I scan my drawing",
            "tellStory": "Tell me {{kind}} about",
            "chooseSourceType": "Choose",
            "sayAndClick": "Say what you want then click here",
            "takePhotoAndClick": "Take a photo of your drawing and click here",
            "start": "Click here to start",
            "send": "Send",
            "wildAnimals": "Example: The wild animals",
            "home": "Home",
            "myChannel": "My Channel",
            "create": "Create",
            "subscriptions": "Subscriptions",
            "hideSolution": "Hide Solution",
            "showSolution": "Show Solution",
            "makeVideoStory": "I create my magic film",
            "makeAudioStory": "I design my audio tale",
            "startFunChallenge": "I solve mysterious riddles",
            "craftMyJokes": "I create my own world of jokes",
            "watchStories": "I dive into my films",
            "listenToStories": "I discover my wonderful tales",
            "funChallenges": "I meet my fantastic riddles",
            "exploreMyJokes": "I explore my universe of jokes",
            "storyInPreparation": "Story in preparation... Why not",
            "listenAnotherStory": "listen to another story",
            "watchAnotherStory": "watch another story",
            "playARiddle": "play a riddle",
            "giggleWithAnotherJoke": "Giggle with another joke",
            "pending": "pending",
            "myAccount": "My account",
            "signOut": "Sign out",
            "signInWithGoogle": "Sign in with Google",
            "featureNotYetAvailable": "Feature not yet available",
            "title": "Title:",
            "yearsOld": "years old",
            "im": "I'm",
            "show": "Show",
            "hide": "Hide",
            "showStory": "Show Story Text",
            "hideStory": "Hide Story Text",
            "surpriseMe": "Surprise me",
            "homeTitle": "Hi, friends! Welcome to our educational app!",
            "homeText": "Here, you're the artist! Create your own video or audio stories.<br /><br />Make riddles and share jokes to laugh with your friends.<br /><br />You can even use a drawing you've made to create a story.<br /><br />And all your creations? We keep them safe so you can review them whenever you want.<br /><br />Ready for the adventure? Come on, let's discover what you can create today!",
            "english":"English",
            "spanish":"Spanish",
            "arabic":"Arabic",
            "french":"French",
            "german":"German",
            "chinese":"Chinese",
            "japanese":"Japanese",
            "hindi":"Hindi",
            "turkish":"Turkish",
            "korean":"Korean",
            "italian":"Italian",
            "dutch":"Dutch",
            "russian":"Russian",
            "indonesian":"Indonesian",
            "alphabet": "Alphabet",
            "numbers": "Numbers",
            "learning": "Learning",
            "languages": "Languages",
            "selectLanguage": "Select a language",
            "selectLearningType": "Select a learning type",
            "words": "Words",
            "selectType": "Select a type",
            "animals": "Animals",
            "colors": "Colors",
            "fruits": "Fruits",
            "vegetables": "Vegetables",
            "foods": "Foods",
            "verbs": "Verbs",
            "houses": "House",
            "schools": "School",
            "supplies": "School supplies",
            "natures": "Nature",
            "places": "places & transport",
            "occupations": "Occupations",
            "iWantToLearn" : "I want to learn"
        }
    },
    es: {
        translation: {
            "story": "una historia",
            "story2": "Historia",
            "riddle": "un desafío",
            "joke": "una broma",
            "mediaSource": "Para solicitar {{kind}}",
            "useKeyboard": "Escribo con el teclado",
            "useMicrophone": "Hablo en el micrófono",
            "usedrawing": "Escaneo mi dibujo",
            "tellStory": "Cuéntame {{kind}} sobre",
            "chooseSourceType": "Elige",
            "sayAndClick": "Di lo que quieras y luego haz clic aquí",
            "takePhotoAndClick": "Toma una foto de tu dibujo y haz clic aquí",
            "start": "Haz clic aquí para comenzar",
            "send": "Enviar",
            "wildAnimals": "Ejemplo: Los animales salvajes",
            "home": "Inicio",
            "myChannel": "Mi canal",
            "create": "Crear",
            "subscriptions": "Suscripciones",
            "hideSolution": "Ocultar la solución",
            "showSolution": "Mostrar la solución",
            "makeVideoStory": "Creo mi película mágica",
            "makeAudioStory": "Diseño mi cuento sonoro",
            "startFunChallenge": "Resuelvo acertijos misteriosos",
            "craftMyJokes": "Creo mi propio universo de bromas",
            "watchStories": "Me sumerjo en mis películas",
            "listenToStories": "Descubro mis cuentos maravillosos",
            "funChallenges": "Encaro mis desafíos fantásticos",
            "exploreMyJokes": "Exploro mi universo de bromas",
            "storyInPreparation": "Historia en preparación... ¿Por qué no",
            "listenAnotherStory": "escuchar otra historia",
            "watchAnotherStory": "ver otra historia",
            "playARiddle": "jugar un acertijo",
            "giggleWithAnotherJoke": "Ríe con otra broma",
            "pending": "pendiente",
            "myAccount": "Mi cuenta",
            "signOut": "Cerrar sesión",
            "signInWithGoogle": "Iniciar sesión con Google",
            "featureNotYetAvailable": "Función no disponible aún",
            "title": "Título:",
            "yearsOld": "años",
            "im": "Tengo",
            "show": "Mostrar",
            "hide": "Ocultar",
            "showStory": "Mostrar texto de la historia",
            "hideStory": "Ocultar texto de la historia",
            "surpriseMe": "Sorpréndeme",
            "homeTitle": "¡Hola, amigos! ¡Bienvenidos a nuestra aplicación educativa!",
            "homeText": "Aquí, tú eres el artista! Crea tus propias historias en video o audio.<br /><br />Haz adivinanzas y comparte chistes para reír con tus amigos.<br /><br />Incluso puedes usar un dibujo que hayas hecho para crear una historia.<br /><br />¿Y todas tus creaciones? Las guardamos con seguridad para que puedas revisarlas cuando quieras.<br /><br />¿Listo para la aventura? Vamos, descubramos lo que puedes crear hoy!",
            "english":"Inglés",
            "spanish":"Español",
            "arabic":"Árabe",
            "french":"Francés",
            "german":"Alemán",
            "chinese":"Chino",
            "japanese":"Japonés",
            "hindi":"Hindi",
            "turkish":"Turco",
            "korean":"Coreano",
            "italian":"Italiano",
            "dutch":"Holandés",
            "russian":"Ruso",
            "indonesian":"Indonesio",
            "alphabet": "Alfabeto",
            "numbers": "Números",
            "learning": "Aprendiendo",
            "languages": "Idiomas",
            "selectLanguage": "Selecciona un idioma",
            "selectLearningType": "Seleccione el tipo de aprendizaje",
            "words": "Palabras",
            "selectType": "Selecciona un tipo",
            "animals": "Animales",
            "colors": "Colores",
            "fruits": "Frutas",
            "vegetables": "Verduras",
            "foods": "Comidas",
            "verbs": "Verbos",
            "houses": "Casa",
            "schools": "Escuela",
            "supplies": "Útiles escolares",
            "natures": "Naturaleza",
            "places": "Lugares y Transporte",
            "occupations": "Ocupaciones",
            "iWantToLearn" : "Quiero aprender"
        }
    },
    ar: {
        translation: {
            "story": "قصة",
            "riddle": "تحدي",
            "joke": "نكتة",
            "mediaSource": "لطلب {{kind}}",
            "useKeyboard": "أكتب على لوحة المفاتيح",
            "useMicrophone": "أتحدث إلى الميكروفون",
            "usedrawing": "استعمل الرسم الخاص بي",
            "tellStory": "احكي لي {{kind}} عن",
            "chooseSourceType": "اختر",
            "sayAndClick": "قل ما تريد ثم انقر هنا",
            "takePhotoAndClick": "التقط صورة لرسمك وانقر هنا",
            "start": "اضغط هنا للبدء",
            "send": "أرسل",
            "wildAnimals": "مثال: الحيوانات البرية",
            "home": "الرئيسية",
            "myChannel": "قناتي",
            "create": "إنشاء",
            "subscriptions": "الاشتراكات",
            "hideSolution": "إخفاء الحل",
            "showSolution": "عرض الحل",
            "makeVideoStory": "أنشئ فيلمي السحري",
            "makeAudioStory": "أصمم حكايتي الصوتية",
            "startFunChallenge": "أحل الألغاز الغامضة",
            "craftMyJokes": "أنشئ عالمي الخاص من النكات",
            "watchStories": "أغوص في أفلامي",
            "listenToStories": "أكتشف قصصي الرائعة",
            "funChallenges": "أواجه تحدياتي الرائعة",
            "exploreMyJokes": "أستكشف كوني من النكت",
            "storyInPreparation": "القصة قيد التحضير... لماذا لا",
            "listenAnotherStory": "استمع لقصة أخرى",
            "watchAnotherStory": "شاهد قصة أخرى",
            "playARiddle": "لعب لغز",
            "giggleWithAnotherJoke": "اضحك مع نكتة أخرى",
            "pending": "قيد الانتظار",
            "myAccount": "حسابي",
            "signOut": "تسجيل الخروج",
            "signInWithGoogle": "Google تسجيل الدخول بواسطة",
            "featureNotYetAvailable": "الميزة غير متاحة بعد",
            "title": "العنوان:",
            "yearsOld": "سنوات",
            "im": "عندي",
            "show": "أظهر",
            "hide": "إخفاء",
            "showStory": "أظهر نص القصة",
            "hideStory": "أخف نص القصة",
            "surpriseMe": "فاجئني",
            "chooseForMe":"",
            "homeTitle": " أصدقائي! مرحبا بكم في تطبيقنا التعليمي",
            "homeText": "هنا، أنت الفنان! قم بإنشاء قصصك الخاصة من خلال الفيديو أو الصوت<br /><br />" +
                "\n" +
                "حل الألغاز وشارك النكت للضحك مع أصدقائك<br /><br />" +
                "\n" +
                "يمكنك استخدام رسم قمت به لإنشاء قصة<br /><br />" +
                "\n" +
                "نحن نحتفظ بجميع إبداعاتك بعناية حتى تتمكن من مراجعتها في أي وقت<br /><br />" +
                "\n" +
                "هل أنت مستعد للمغامرة؟ تعال و اكتشف ما يمكنك إنشاؤه اليوم\n" +
                "\n",
            "english":"إنجليزية",
            "spanish":"إسبانية",
            "arabic":"عربى",
            "french":"فرنسي",
            "german":"ألمانية",
            "chinese":"صينى",
            "japanese":"يابانية",
            "hindi":"هندي",
            "turkish":"تركي",
            "korean":"كوري",
            "italian":"إيطالي",
            "dutch":"هولندي",
            "russian":"روسي",
            "indonesian":"إندونيسي",
            "alphabet": "الحروف الأبجدية",
            "numbers": "الأرقام",
            "learning": "التعلم",
            "languages": "اللغات",
            "selectLanguage": "اختر اللغة",
            "selectLearningType": "اختر نوع التعلم",
            "words": "الكلمات",
            "selectType": "اختر نوعا",
            "animals": "الحيوانات",
            "colors": "الألوان",
            "fruits": "الفواكه",
            "vegetables": "الخضروات",
            "foods": "أطعمة",
            "verbs": "أفعال",
            "houses": "منزل",
            "schools": "مدرسة",
            "supplies": "لوازم مدرسية",
            "natures": "الطبيعة",
            "places": "الأماكن والنقل",
            "occupations": "المهن",
            "iWantToLearn" : "أريد أن أتعلم"
        }
    },
    de: {
        translation: {
            "story": "eine Geschichte",
            "riddle": "eine Herausforderung",
            "joke": "ein Witz",
            "mediaSource": "Um {{kind}} zu beantragen",
            "useKeyboard": "Ich tippe auf der Tastatur",
            "useMicrophone": "Ich spreche ins Mikrofon",
            "usedrawing": "Ich scanne meine Zeichnung",
            "tellStory": "Erzähl mir {{kind}} über",
            "chooseSourceType": "Wählen",
            "sayAndClick": "Sag was du willst und klicke dann hier",
            "takePhotoAndClick": "Mach ein Foto von deiner Zeichnung und klicke hier",
            "start": "Klicke hier zum starten",
            "send": "Senden",
            "wildAnimals": "Beispiel: Die wilden Tiere",
            "home": "Startseite",
            "myChannel": "Mein Kanal",
            "create": "Erstellen",
            "subscriptions": "Abonnements",
            "hideSolution": "Lösung verbergen",
            "showSolution": "Lösung anzeigen",
            "makeVideoStory": "Ich erstelle meinen Zauberfilm",
            "makeAudioStory": "Ich entwerfe meine Hör-Geschichte",
            "startFunChallenge": "Ich löse geheimnisvolle Rätsel",
            "craftMyJokes": "Ich kreiere meine eigene Welt von Witzen",
            "watchStories": "Ich tauche in meine Filme ein",
            "listenToStories": "Ich entdecke meine wundervollen Geschichten",
            "funChallenges": "Ich stelle mich meinen fantastischen Herausforderungen",
            "exploreMyJokes": "Ich erkunde mein Universum von Witzen",
            "storyInPreparation": "Geschichte in Vorbereitung... Warum nicht",
            "listenAnotherStory": "hören Sie eine andere Geschichte",
            "watchAnotherStory": "sehen Sie eine andere Geschichte",
            "playARiddle": "ein Rätsel spielen",
            "giggleWithAnotherJoke": "Kichere mit einem anderen Witz",
            "pending": "steht aus",
            "myAccount": "Mein Konto",
            "signOut": "Abmelden",
            "signInWithGoogle": "Mit Google anmelden",
            "featureNotYetAvailable": "Funktion noch nicht verfügbar",
            "title": "Titel:",
            "yearsOld": "Jahre alt",
            "im": "Ich bin",
            "show": "Zeigen",
            "hide": "Verbergen",
            "showStory": "Geschichtentext anzeigen",
            "hideStory": "Geschichtentext verbergen",
            "surpriseMe": "Überrasche mich",
            "chooseForMe":"",
            "homeTitle": "Hallo, Freunde! Willkommen zu unserer Lern-App!",
            "homeText": "Hier bist du der Künstler! Erstelle deine eigenen Video- oder Audiogeschichten.<br /><br />Mache Rätsel und teile Witze, um mit deinen Freunden zu lachen.<br /><br />Du kannst sogar eine Zeichnung verwenden, die du gemacht hast, um eine Geschichte zu erstellen.<br /><br />Und all deine Kreationen? Wir bewahren sie sicher auf, damit du sie jederzeit überprüfen kannst.<br /><br />Bereit für das Abenteuer? Los, lass uns entdecken, was du heute erschaffen kannst!",
            "english":"Englisch",
            "spanish":"Spanisch",
            "arabic":"Arabisch",
            "french":"Französisch",
            "german":"Deutsch",
            "chinese":"Chinesisch",
            "japanese":"Japanisch",
            "hindi":"Hindi",
            "turkish":"Türkisch",
            "korean":"Koreanisch",
            "italian":"Italienisch",
            "dutch":"Niederländisch",
            "russian":"Russisch",
            "indonesian":"Indonesisch",
            "alphabet": "Alphabet",
            "numbers": "Zahlen",
            "learning": "Lernen",
            "languages": "Sprachen",
            "selectLanguage": "Wählen Sie eine Sprache",
            "selectLearningType": "Wählen Sie eine Lernart",
            "words": "Wörter",
            "selectType": "Wähle einen Typ",
            "animals": "Tiere",
            "colors": "Farben",
            "fruits": "Früchte",
            "vegetables": "Gemüse",
            "foods": "Lebensmittel",
            "verbs": "Verben",
            "houses": "Haus",
            "schools": "Schule",
            "supplies": "Schulmaterial",
            "natures": "Natur",
            "places": "Orte & Verkehrsmittel",
            "occupations": "Berufe",
            "iWantToLearn" : "Ich möchte lernen"
        }
    },
    fr: {
        translation: {
            "story": "une histoire",
            "riddle": "un défi",
            "joke": "une blague",
            "mediaSource": "Pour demander {{kind}}",
            "useKeyboard": "Je tape au clavier",
            "useMicrophone": "Je parle au micro",
            "usedrawing": "Je scanne mon dessin",
            "tellStory": "Raconte moi {{kind}} sur",
            "chooseSourceType": "Choisis",
            "sayAndClick": "Dis ce que tu veux puis click ici",
            "takePhotoAndClick": "Prends une photo de ton dessin et clique ici",
            "start": "Click ici pour commencer",
            "send": "Envoyer",
            "wildAnimals": "Exemple: Les animaux sauvages",
            "home": "Accueil",
            "myChannel": "Ma chaine",
            "create": "Créer",
            "subscriptions": "Abonnements",
            "hideSolution": "Masquer la solution",
            "showSolution": "Afficher la solution",
            "makeVideoStory": "Je crée mon film magique",
            "makeAudioStory": "Je conçois mon conte sonore",
            "startFunChallenge": "Je résous des devinettes mystérieuses",
            "craftMyJokes": "Je créer mon univers de blagues",
            "watchStories": "Je plonge dans mes films",
            "listenToStories": "Je découvre mes contes merveilleux",
            "funChallenges": "Je relève mes défis fantastiques",
            "exploreMyJokes": "J'explore mon univers de blagues",
            "storyInPreparation": "Histoire en préparation... Pourquoi ne pas",
            "listenAnotherStory": "écouter une autre histoire",
            "watchAnotherStory": "regaredr une autre histoire",
            "playARiddle": "jouer à une devinette",
            "giggleWithAnotherJoke": "rigole avec une autre blague",
            "pending": "en attendant",
            "myAccount": "Mon compte",
            "signOut": "Se déconnecter",
            "signInWithGoogle": "Se connecter avec Google",
            "featureNotYetAvailable": "Fonctionnalité pas encore disponible",
            "title": "Titre:",
            "yearsOld": "ans",
            "im": "J'ai",
            "show": "Afficher",
            "hide": "Cacher",
            "showStory": "Afficher le texte de l'histoire",
            "hideStory": "Cacher le texte de l'histoire",
            "surpriseMe": "Surprends-moi",
            "homeTitle": "Salut les amis ! Bienvenue sur notre application éducative !",
            "homeText": "Ici, c'est toi l'artiste ! Crée tes propres histoires en vidéo ou en audio.<br /><br />Fais deviner des énigmes et partage des blagues pour rigoler avec tes amis.<br /><br />Tu peux même utiliser un dessin que tu as fait pour créer une histoire.<br /><br />Et toutes tes créations ? On les garde précieusement pour que tu puisses les revoir quand tu veux.<br /><br />Prêt pour l'aventure ? Allez, viens découvrir ce que tu peux créer aujourd'hui !",
            "english":"Anglais",
            "spanish":"Espagnol",
            "arabic":"Arabe",
            "french":"Français",
            "german":"Allemand",
            "chinese":"Chinois",
            "japanese":"Japonais",
            "hindi":"Hindi",
            "turkish":"Turc",
            "korean":"Coréen",
            "italian":"Italien",
            "dutch":"Néerlandais",
            "russian":"Russe",
            "indonesian":"Indonésien",
            "alphabet": "Alphabet",
            "numbers": "Nombres",
            "learning": "Apprentissage",
            "languages": "Langues",
            "selectLanguage": "Sélectionne une langue",
            "selectLearningType": "Sélectionne un type d'apprentissage",
            "words": "Mots",
            "selectType": "Sélectionnez un type",
            "animals": "Animaux",
            "colors": "Couleurs",
            "fruits": "Fruits",
            "vegetables": "Légumes",
            "foods": "Aliments",
            "verbs": "Verbes",
            "houses": "Maison",
            "schools": "École",
            "supplies": "Fournitures scolaires",
            "natures": "Nature",
            "places": "Lieux et Transports",
            "occupations": "Métiers",
            "iWantToLearn" : "Je veux apprendre"
        }
    },
    zh: {
        translation: {
            "story": "一个故事",
            "riddle": "一个挑战",
            "joke": "一个笑话",
            "mediaSource": "要申请{{kind}}",
            "useKeyboard": "我在键盘上打字",
            "useMicrophone": "我在麦克风上说话",
            "usedrawing": "我扫描我的画",
            "tellStory": "告诉我{{kind}}关于",
            "chooseSourceType": "选择",
            "sayAndClick": "说出你想说的，然后点击这里",
            "takePhotoAndClick": "拍下你的画作并点击这里",
            "start": "点击这里开始",
            "send": "发送",
            "wildAnimals": "例子: 野生动物",
            "home": "主页",
            "myChannel": "我的频道",
            "create": "创建",
            "subscriptions": "订阅",
            "hideSolution": "隐藏解决方案",
            "showSolution": "显示解决方案",
            "makeVideoStory": "我创建我的魔术电影",
            "makeAudioStory": "我设计我的声音故事",
            "startFunChallenge": "我解决神秘的谜语",
            "craftMyJokes": "我创造了我自己的笑话世界",
            "watchStories": "我沉浸在我的电影中",
            "listenToStories": "我发现我的奇妙故事",
            "funChallenges": "我面临我精彩的挑战",
            "exploreMyJokes": "我探索我的笑话的宇宙",
            "storyInPreparation": "故事准备中... 为什么不",
            "listenAnotherStory": "听另一个故事",
            "watchAnotherStory": "看另一个故事",
            "playARiddle": "玩一个谜语",
            "giggleWithAnotherJoke": "和另一个笑话一起咯咯笑",
            "pending": "待定",
            "myAccount": "我的账户",
            "signOut": "登出",
            "signInWithGoogle": "使用Google登录",
            "featureNotYetAvailable": "功能尚未提供",
            "title": "标题:",
            "yearsOld": "岁",
            "im": "我",
            "show": "显示",
            "hide": "隐藏",
            "showStory": "显示故事文本",
            "hideStory": "隐藏故事文本",
            "surpriseMe": "给我一个惊喜",
            "chooseForMe":"",
            "homeTitle": "朋友们，欢迎来到我们的教育应用程序！",
            "homeText": "在这里，你就是艺术家！创建你自己的视频或音频故事。<br /><br />设计谜语和分享笑话，让你和朋友们开怀大笑。<br /><br />你甚至可以使用你的绘图来创造一个故事。<br /><br />所有的创作？我们会小心保管，让你可以随时回看。<br /><br />准备好冒险了吗？快来发现你今天可以创造什么吧！",
            "english":"英语",
            "spanish":"西班牙语",
            "arabic":"阿拉伯语",
            "french":"法语",
            "german":"德语",
            "chinese":"中文",
            "japanese":"日语",
            "hindi":"印地语",
            "turkish":"土耳其语",
            "korean":"韩语",
            "italian":"意大利语",
            "dutch":"荷兰语",
            "russian":"俄语",
            "indonesian":"印度尼西亚语",
            "alphabet": "字母表",
            "numbers": "数字",
            "learning": "学习",
            "languages": "语言",
            "selectLanguage": "选择语言",
            "selectLearningType": "选择学习类型",
            "words": "单词",
            "selectType": "选择类型",
            "animals": "动物",
            "colors": "颜色",
            "fruits": "水果",
            "vegetables": "蔬菜",
            "foods": "食物",
            "verbs": "动词",
            "houses": "房子",
            "schools": "学校",
            "supplies": "学校用品",
            "natures": "自然",
            "places": "地点与交通",
            "occupations": "职业",
            "iWantToLearn" : "我想学习"
        }
    },
    hi: {
        translation: {
            "story": "एक कहानी",
            "riddle": "एक चुनौती",
            "joke": "एक मजाक",
            "mediaSource": "{{kind}} का अनुरोध करने के लिए",
            "useKeyboard": "मैं कीबोर्ड पर टाइप करता हूँ",
            "useMicrophone": "मैं माइक्रोफ़ोन में बोलता हूँ",
            "usedrawing": "मैं अपना चित्र स्कैन करता हूँ",
            "tellStory": "मुझे {{kind}} के बारे में बताएं",
            "chooseSourceType": "चुनें",
            "sayAndClick": "जो कुछ आप चाहते हैं वह कहें फिर यहाँ क्लिक करें",
            "takePhotoAndClick": "अपनी ड्राइंग की फ़ोटो खींचो और यहां क्लिक करो",
            "start": "शुरू करने के लिए यहां क्लिक करें",
            "send": "भेजें",
            "wildAnimals": "उदाहरण: जंगली जानवर",
            "home": "होम",
            "myChannel": "मेरा चैनल",
            "create": "बनाएं",
            "subscriptions": "सदस्यताएं",
            "hideSolution": "समाधान छुपाएं",
            "showSolution": "समाधान दिखाएं",
            "makeVideoStory": "मैं अपनी जादुई फिल्म बनाता हूँ",
            "makeAudioStory": "मैं अपनी ऑडियो कहानी का डिजाइन करता हूं",
            "startFunChallenge": "मैं रहस्यमय पहेलियाँ सुलझाता हूँ",
            "craftMyJokes": "मैं अपनी जोक्स की दुनिया बनाता हूं",
            "watchStories": "मैं अपनी फिल्मों में डूब जाता हूँ",
            "listenToStories": "मैं अपनी अद्भुत कथाएं खोजता हूँ",
            "funChallenges": "मैं अपनी आश्चर्यजनक चुनौतियों का सामना करता हूँ",
            "exploreMyJokes": "मैं अपने चुटकुले का ब्रह्मांड अन्वेषण करता हूं",
            "storyInPreparation": "कहानी तैयारी में... क्यों नहीं",
            "listenAnotherStory": "एक और कहानी सुनें",
            "watchAnotherStory": "एक और कहानी देखें",
            "playARiddle": "एक पहेली खेलें",
            "giggleWithAnotherJoke": "एक और मजाक के साथ हंसिये",
            "pending": "लंबित",
            "myAccount": "मेरा खाता",
            "signOut": "साइन आउट",
            "signInWithGoogle": "Google के साथ साइन इन करें",
            "featureNotYetAvailable": "सुविधा अभी तक उपलब्ध नहीं है",
            "title": "शीर्षक:",
            "yearsOld": "साल के",
            "im": "मेरी उम्र",
            "show": "दिखाएं",
            "hide": "छुपाएं",
            "showStory": "कहानी का पाठ दिखाएं",
            "hideStory": "कहानी का पाठ छुपाएं",
            "surpriseMe": "मुझे चौंका दो",
            "chooseForMe":"",
            "homeTitle": "नमस्ते दोस्तों! हमारे शैक्षिक ऐप में आपका स्वागत है!",
            "homeText": "यहां, आप कला का सर्जन करते हैं! अपनी वीडियो या ऑडियो कहानियों को बनाएँ।<br /><br />पहेलियों को सुलझाएं और अपने मित्रों के साथ हंसी के लिए मजाक बाँटें।<br /><br />आप अपनी बनाई हुई चित्र का उपयोग करके एक कहानी बना सकते हैं।<br /><br />और आपके सभी रचनाएं? हम उन्हें ध्यानपूर्वक रखते हैं ताकि आप उन्हें जब चाहें देख सकें।<br /><br />एडवेंचर के लिए तैयार? चलिए, देखते हैं कि आप आज क्या बना सकते हैं!",
            "english":"अंग्रेज़ी",
            "spanish":"स्पेनिश",
            "arabic":"अरबी",
            "french":"फ़्रेंच",
            "german":"जर्मन",
            "chinese":"चीनी",
            "japanese":"जापानी",
            "hindi":"हिंदी",
            "turkish":"तुर्की",
            "korean":"कोरियाई",
            "italian":"इटालियन",
            "dutch":"डच",
            "russian":"रूसी",
            "indonesian":"इंडोनेशियाई",
            "alphabet": "वर्णमाला",
            "numbers": "संख्याएँ",
            "learning": "सीखना",
            "languages": "भाषाएं",
            "selectLanguage": "एक भाषा चुनें",
            "selectLearningType": "एक सीखने का प्रकार चुनें",
            "words": "शब्द",
            "selectType": "प्रकार चुनें",
            "animals": "जानवर",
            "colors": "रंग",
            "fruits": "फल",
            "vegetables": "सब्ज़ियाँ",
            "foods": "खाना",
            "verbs": "क्रियाएँ",
            "houses": "घर",
            "schools": "स्कूल",
            "supplies": "स्कूल सामग्री",
            "natures": "प्रकृति",
            "places": "स्थान और परिवहन",
            "occupations": "व्यापार",
            "iWantToLearn" : "मैं सीखना चाहता हूँ"
        }
    },
    ja: {
        translation: {
            "story": "物語",
            "riddle": "挑戦",
            "joke": "冗談",
            "mediaSource": "{{kind}} を要求するために",
            "useKeyboard": "私はキーボードで打つ",
            "useMicrophone": "私はマイクで話す",
            "usedrawing": "私の描いた絵をスキャンする",
            "tellStory": "{{kind}}について話してください",
            "chooseSourceType": "選択してください",
            "sayAndClick": "言いたいことを言ってからここをクリックしてください",
            "takePhotoAndClick": "あなたの絵の写真を撮ってここをクリックしてください",
            "start": "ここをクリックして開始してください",
            "send": "送る",
            "wildAnimals": "例：野生動物",
            "home": "ホーム",
            "myChannel": "マイチャンネル",
            "create": "作成",
            "subscriptions": "購読",
            "hideSolution": "解決策を隠す",
            "showSolution": "解決策を表示",
            "makeVideoStory": "私は魔法の映画を作ります",
            "makeAudioStory": "私は自分のオーディオ物語をデザインします",
            "startFunChallenge": "私は謎めいたパズルを解きます",
            "craftMyJokes": "私は自分だけのジョークの世界を作り出します",
            "watchStories": "私は自分の映画に没頭します",
            "listenToStories": "私は素晴らしい話を発見します",
            "funChallenges": "私は素晴らしい挑戦に立ち向かいます",
            "exploreMyJokes": "私のジョークの宇宙を探索する",
            "storyInPreparation": "ストーリーの準備中... なぜなら",
            "listenAnotherStory": "別の物語を聞く",
            "watchAnotherStory": "別の物語を見る",
            "playARiddle": "謎を遊ぶ",
            "giggleWithAnotherJoke": "別のジョークでくすくす笑う",
            "pending": "保留中",
            "myAccount": "マイアカウント",
            "signOut": "サインアウト",
            "signInWithGoogle": "Googleでサインイン",
            "featureNotYetAvailable": "まだ利用できない機能",
            "title": "タイトル:",
            "yearsOld": "歳",
            "im": "私は",
            "show": "表示する",
            "hide": "隠す",
            "showStory": "ストーリーテキストを表示する",
            "hideStory": "ストーリーテキストを隠す",
            "surpriseMe": "驚かせてください",
            "chooseForMe":"",
            "homeTitle": "こんにちは、友達！私たちの教育アプリへようこそ！",
            "homeText": "ここでは、あなたがアーティストです！自分だけのビデオやオーディオの物語を作りましょう。<br /><br />なぞなぞを出題したり、笑いを共有するためのジョークを作ったりします。<br /><br />自分の描いた絵を使って物語を作ることもできます。<br /><br />すべての作品は大切に保存されますので、いつでも見直すことができます。<br /><br />冒険に準備はいいですか？さあ、今日何を作ることができるか見てみましょう！",
            "english":"英語",
            "spanish":"スペイン語",
            "arabic":"アラビア語",
            "french":"フランス語",
            "german":"ドイツ語",
            "chinese":"中国語",
            "japanese":"日本語",
            "hindi":"ヒンディー語",
            "turkish":"トルコ語",
            "korean":"韓国語",
            "italian":"イタリア語",
            "dutch":"オランダ語",
            "russian":"ロシア語",
            "indonesian":"インドネシア語",
            "alphabet": "アルファベット",
            "numbers": "数字",
            "learning": "学習",
            "languages": "言語",
            "selectLanguage": "言語を選択",
            "selectLearningType": "学習タイプを選択",
            "words": "言葉",
            "selectType": "タイプを選択",
            "animals": "動物",
            "colors": "色",
            "fruits": "果物",
            "vegetables": "野菜",
            "foods": "食べ物",
            "verbs": "動詞",
            "houses": "家",
            "schools": "学校",
            "supplies": "学校の用品",
            "natures": "自然",
            "places": "場所と交通",
            "occupations": "職業",
            "iWantToLearn" : "私は学びたい"
        }
    },
    it: {
        translation: {
            "story": "una storia",
            "riddle": "una sfida",
            "joke": "una barzelletta",
            "mediaSource": "Per richiedere {{kind}}",
            "useKeyboard": "Digito sulla tastiera",
            "useMicrophone": "Parlo al microfono",
            "usedrawing": "Scansiono il mio disegno",
            "tellStory": "Raccontami {{kind}} su",
            "chooseSourceType": "Scegli",
            "sayAndClick": "Dì quello che vuoi e poi clicca qui",
            "takePhotoAndClick": "Scatta una foto del tuo disegno e clicca qui",
            "start": "Clicca qui per iniziare",
            "send": "Inviare",
            "wildAnimals": "Esempio: Animali selvaggi",
            "home": "Home",
            "myChannel": "Il mio canale",
            "create": "Creare",
            "subscriptions": "Abbonamenti",
            "hideSolution": "Nascondi la soluzione",
            "showSolution": "Mostra la soluzione",
            "makeVideoStory": "Creo il mio film magico",
            "makeAudioStory": "Progetto il mio racconto sonoro",
            "startFunChallenge": "Risolvo enigmi misteriosi",
            "craftMyJokes": "Creo il mio mondo di barzellette",
            "watchStories": "Mi immergo nei miei film",
            "listenToStories": "Scopro i miei racconti meravigliosi",
            "funChallenges": "Affronto le mie sfide fantastiche",
            "exploreMyJokes": "Esploro il mio universo di scherzi",
            "storyInPreparation": "Storia in preparazione... Perché no",
            "listenAnotherStory": "ascolta un'altra storia",
            "watchAnotherStory": "guarda un'altra storia",
            "playARiddle": "gioca un indovinello",
            "giggleWithAnotherJoke": "Ridere con un altro scherzo",
            "pending": "in attesa",
            "myAccount": "Il mio account",
            "signOut": "Esci",
            "signInWithGoogle": "Accedi con Google",
            "featureNotYetAvailable": "Funzionalità non ancora disponibile",
            "title": "Titolo:",
            "yearsOld": "anni",
            "im": "Ho",
            "show": "Mostra",
            "hide": "Nascondi",
            "showStory": "Mostra il testo della storia",
            "hideStory": "Nascondi il testo della storia",
            "surpriseMe": "Sorprendimi",
            "chooseForMe":"",
            "homeTitle": "Ciao, amici! Benvenuti alla nostra app educativa!",
            "homeText": "Qui, sei tu l'artista! Crea le tue storie in video o audio.<br /><br />Fai indovinelli e condividi barzellette per ridere con i tuoi amici.<br /><br />Puoi persino utilizzare un disegno che hai fatto per creare una storia.<br /><br />E tutte le tue creazioni? Le conserviamo al sicuro in modo che tu possa rivederle quando vuoi.<br /><br />Pronto per l'avventura? Dai, scopriamo cosa puoi creare oggi!",
            "english":"Inglese",
            "spanish":"Spagnolo",
            "arabic":"Arabo",
            "french":"Francese",
            "german":"Tedesco",
            "chinese":"Cinese",
            "japanese":"Giapponese",
            "hindi":"Hindi",
            "turkish":"Turco",
            "korean":"Coreano",
            "italian":"Italiano",
            "dutch":"Olandese",
            "russian":"Russo",
            "indonesian":"Indonesiano",
            "alphabet": "Alfabeto",
            "numbers": "Numeri",
            "learning": "Apprendimento",
            "languages": "Lingue",
            "selectLanguage": "Seleziona una lingua",
            "selectLearningType": "Seleziona il tipo di apprendimento",
            "words": "Parole",
            "selectType": "Seleziona un tipo",
            "animals": "Animali",
            "colors": "Colori",
            "fruits": "Frutta",
            "vegetables": "Verdure",
            "foods": "Cibi",
            "verbs": "Verbi",
            "houses": "Casa",
            "schools": "Scuola",
            "supplies": "Forniture scolastiche",
            "natures": "Natura",
            "places": "Luoghi e Trasporti",
            "occupations": "Professioni",
            "iWantToLearn" : "Voglio imparare"
        }
    },
    ko: {
        translation: {
            "story": "이야기",
            "riddle": "도전",
            "joke": "농담",
            "mediaSource": "{{kind}} 요청하기 위해",
            "useKeyboard": "키보드로 입력합니다",
            "useMicrophone": "마이크로 말합니다",
            "usedrawing": "내 그림을 스캔합니다",
            "tellStory": "{{kind}}에 대해 이야기해주세요",
            "chooseSourceType": "선택하세요",
            "sayAndClick": "원하는 것을 말한 다음 여기를 클릭하세요",
            "takePhotoAndClick": "그림의 사진을 찍고 여기를 클릭해",
            "start": "시작하려면 여기를 클릭하세요",
            "send": "보내다",
            "wildAnimals": "예시: 야생동물",
            "home": "홈",
            "myChannel": "내 채널",
            "create": "생성",
            "subscriptions": "구독",
            "hideSolution": "해결책 숨기기",
            "showSolution": "해결책 보이기",
            "makeVideoStory": "나는 내 마법의 영화를 만든다",
            "makeAudioStory": "나는 나만의 오디오 이야기를 디자인합니다",
            "startFunChallenge": "나는 수수께끼를 풀어낸다",
            "craftMyJokes": "나는 나만의 농담 세계를 만들어요",
            "watchStories": "나는 내 영화에 빠져든다",
            "listenToStories": "나는 내 놀라운 이야기를 발견한다",
            "funChallenges": "나는 내 환상적인 도전을 만난다",
            "exploreMyJokes": "나는 내 농담의 우주를 탐험한다",
            "storyInPreparation": "이야기 준비 중... 왜 안돼",
            "listenAnotherStory": "다른 이야기 듣기",
            "watchAnotherStory": "다른 이야기 보기",
            "playARiddle": "수수께끼를 풀다",
            "giggleWithAnotherJoke": "또 다른 농담으로 킥킥 거리다",
            "pending": "대기 중",
            "myAccount": "내 계정",
            "signOut": "로그 아웃",
            "signInWithGoogle": "Google로 로그인",
            "featureNotYetAvailable": "아직 사용할 수 없는 기능",
            "title": "제목:",
            "yearsOld": "세",
            "im": "나는",
            "show": "보이기",
            "hide": "숨기기",
            "showStory": "스토리 텍스트 표시",
            "hideStory": "스토리 텍스트 숨기기",
            "surpriseMe": "놀라게 해주세요",
            "chooseForMe":"",
            "homeTitle": "친구 여러분, 안녕하세요! 우리의 교육 앱에 오신 것을 환영합니다!",
            "homeText": "여기서는 당신이 아티스트입니다! 비디오나 오디오로 자신만의 이야기를 만드세요.<br /><br />수수께끼를 내고 친구들과 함께 웃을 수 있는 농담을 공유하세요.<br /><br />직접 그린 그림을 이용하여 이야기를 만들 수도 있어요.<br /><br />그리고 당신의 모든 창작물들? 우리는 그것들을 안전하게 보관해서 언제든지 다시 볼 수 있게 해줍니다.<br /><br />모험을 준비하셨나요? 그럼, 오늘 당신이 만들 수 있는 것들을 발견해보세요!",
            "english":"영어",
            "spanish":"스페인어",
            "arabic":"아라비아어",
            "french":"프랑스어",
            "german":"독일어",
            "chinese":"중국어",
            "japanese":"일본어",
            "hindi":"힌디어",
            "turkish":"터키어",
            "korean":"한국어",
            "italian":"이탈리아어",
            "dutch":"네덜란드어",
            "russian":"러시아어",
            "indonesian":"인도네시아어",
            "alphabet": "알파벳",
            "numbers": "숫자들",
            "learning": "학습",
            "languages": "언어",
            "selectLanguage": "언어 선택",
            "selectLearningType": "학습 유형 선택",
            "words": "단어",
            "selectType": "타입 선택",
            "animals": "동물",
            "colors": "색깔",
            "fruits": "과일",
            "vegetables": "채소",
            "foods": "음식",
            "verbs": "동사",
            "houses": "집",
            "schools": "학교",
            "supplies": "학교 용품",
            "natures": "자연",
            "places": "장소와 교통",
            "occupations": "직업",
            "iWantToLearn" : "나는 배우고 싶다"
        }
    },
    nl: {
        translation: {
            "story": "een verhaal",
            "riddle": "een uitdaging",
            "joke": "een grap",
            "mediaSource": "Om {{kind}} aan te vragen",
            "useKeyboard": "Ik typ op het toetsenbord",
            "useMicrophone": "Ik spreek in de microfoon",
            "usedrawing": "Ik scan mijn tekening",
            "tellStory": "Vertel me {{kind}} over",
            "chooseSourceType": "Kies",
            "sayAndClick": "Zeg wat je wilt en klik dan hier",
            "takePhotoAndClick": "Maak een foto van je tekening en klik hier",
            "start": "Klik hier om te beginnen",
            "send": "Verzenden",
            "wildAnimals": "Voorbeeld: Wilde dieren",
            "home": "Thuis",
            "myChannel": "Mijn kanaal",
            "create": "Creëren",
            "subscriptions": "Abonnementen",
            "hideSolution": "Oplossing verbergen",
            "showSolution": "Oplossing tonen",
            "makeVideoStory": "Ik maak mijn magische film",
            "makeAudioStory": "Ik ontwerp mijn eigen geluidsverhaal",
            "startFunChallenge": "Ik los mysterieuze raadsels op",
            "craftMyJokes": "Ik creëer mijn eigen wereld van grappen",
            "watchStories": "Ik duik in mijn films",
            "listenToStories": "Ik ontdek mijn wonderbaarlijke verhalen",
            "funChallenges": "Ik ga mijn fantastische uitdagingen aan",
            "exploreMyJokes": "Ik verken mijn universum van grappen",
            "storyInPreparation": "Verhaal in voorbereiding... Waarom niet",
            "listenAnotherStory": "luister naar een ander verhaal",
            "watchAnotherStory": "bekijk een ander verhaal",
            "playARiddle": "speel een raadsel",
            "giggleWithAnotherJoke": "Giechel met een andere grap",
            "pending": "in afwachting",
            "myAccount": "Mijn account",
            "signOut": "Uitloggen",
            "signInWithGoogle": "Aanmelden met Google",
            "featureNotYetAvailable": "Functie nog niet beschikbaar",
            "title": "Titel:",
            "yearsOld": "jaar oud",
            "im": "Ik ben",
            "show": "Tonen",
            "hide": "Verbergen",
            "showStory": "Toon verhaaltekst",
            "hideStory": "Verberg verhaaltekst",
            "surpriseMe": "Verras me",
            "chooseForMe":"",
            "homeTitle": "Hallo vrienden! Welkom bij onze educatieve app!",
            "homeText": "Hier ben jij de artiest! Maak je eigen video- of audioverhalen.<br /><br />Maak raadsels en deel grappen om te lachen met je vrienden.<br /><br />Je kunt zelfs een tekening die je hebt gemaakt gebruiken om een verhaal te creëren.<br /><br />En al je creaties? We bewaren ze veilig zodat je ze altijd kunt bekijken.<br /><br />Klaar voor het avontuur? Kom op, laten we ontdekken wat je vandaag kunt maken!",
            "english":"Engels",
            "spanish":"Spaans",
            "arabic":"Arabisch",
            "french":"Frans",
            "german":"Duits",
            "chinese":"Chinees",
            "japanese":"Japans",
            "hindi":"Hindi",
            "turkish":"Turks",
            "korean":"Koreaans",
            "italian":"Italiaans",
            "dutch":"Nederlands",
            "russian":"Russisch",
            "indonesian":"Indonesisch",
            "alphabet": "Alfabet",
            "numbers": "Nummers",
            "learning": "Leren",
            "languages": "Talen",
            "selectLanguage": "Selecteer een taal",
            "selectLearningType": "Selecteer een leertype",
            "words": "Woorden",
            "selectType": "Selecteer een type",
            "animals": "Dieren",
            "colors": "Kleuren",
            "fruits": "Fruit",
            "vegetables": "Groenten",
            "foods": "Voedsel",
            "verbs": "Werkwoorden",
            "houses": "Huis",
            "schools": "School",
            "supplies": "Schoolbenodigdheden",
            "natures": "Natuur",
            "places": "Plaatsen en Vervoer",
            "occupations": "Beroepen",
            "iWantToLearn" : "Ik wil leren"
        }
    },
    tr: {
        translation: {
            "story": "bir hikaye",
            "riddle": "bir meydan okuma",
            "joke": "bir şaka",
            "mediaSource": "{{kind}} talep etmek için",
            "useKeyboard": "Klavyede yazıyorum",
            "useMicrophone": "Mikrofonda konuşuyorum",
            "usedrawing": "Çizimimi tarıyorum",
            "tellStory": "Bana {{kind}} hakkında anlat",
            "chooseSourceType": "Seç",
            "sayAndClick": "İstediğinizi söyleyin sonra buraya tıklayın",
            "takePhotoAndClick": "Çiziminin fotoğrafını çek ve buraya tıkla",
            "start": "Başlamak için buraya tıklayın",
            "send": "Gönder",
            "wildAnimals": "Örnek: Yabani hayvanlar",
            "home": "Ana Sayfa",
            "myChannel": "Kanalım",
            "create": "Oluştur",
            "subscriptions": "Abonelikler",
            "hideSolution": "Çözümü gizle",
            "showSolution": "Çözümü göster",
            "makeVideoStory": "Büyülü filmimi oluşturuyorum",
            "makeAudioStory": "Kendi sesli hikayemi tasarlıyorum",
            "startFunChallenge": "Gizemli bulmacaları çözüyorum",
            "craftMyJokes": "Kendi şaka dünyamı yaratırım",
            "watchStories": "Filmlerime dalarım",
            "listenToStories": "Harika hikayelerimi keşfediyorum",
            "funChallenges": "Muhteşem zorluklarımı aşarım",
            "exploreMyJokes": "Ben şaka evrenimi keşfediyorum",
            "storyInPreparation": "Hikaye hazırlanıyor... Neden olmasın",
            "listenAnotherStory": "başka bir hikaye dinle",
            "watchAnotherStory": "başka bir hikaye izle",
            "playARiddle": "bir bilmece oyna",
            "giggleWithAnotherJoke": "Başka bir şaka ile kıkırdama",
            "pending": "beklemede",
            "myAccount": "Hesabım",
            "signOut": "Çıkış Yap",
            "signInWithGoogle": "Google ile giriş yap",
            "featureNotYetAvailable": "Özellik henüz kullanılamıyor",
            "title": "Başlık:",
            "yearsOld": "yaşında",
            "im": "Ben",
            "show": "Göster",
            "hide": "Gizle",
            "showStory": "Hikaye Metnini Göster",
            "hideStory": "Hikaye Metnini Gizle",
            "surpriseMe": "Beni şaşırt",
            "chooseForMe":"",
            "homeTitle": "Merhaba arkadaşlar! Eğitim uygulamamıza hoş geldiniz!",
            "homeText": "Burada sen sanatçısın! Kendi video veya sesli hikayelerini oluştur.<br /><br />Bilmeceler oluştur ve arkadaşlarınla gülebileceğin şakaları paylaş.<br /><br />Bir hikaye oluşturmak için yaptığın bir çizimi bile kullanabilirsin.<br /><br />Ve tüm eserlerin mi? Onları güvenli bir şekilde saklarız, böylece istediğin zaman tekrar görebilirsin.<br /><br />Macera için hazır mısın? Hadi, bugün ne oluşturabileceğini keşfedelim!",
            "english":"İngilizce",
            "spanish":"İspanyolca",
            "arabic":"Arapça",
            "french":"Fransızca",
            "german":"Almanca",
            "chinese":"Çince",
            "japanese":"Japonca",
            "hindi":"Hintçe",
            "turkish":"Türkçe",
            "korean":"Korece",
            "italian":"İtalyanca",
            "dutch":"Hollandaca",
            "russian":"Rusça",
            "indonesian":"Endonezyaca",
            "alphabet": "Alfabe",
            "numbers": "Sayılar",
            "learning": "Öğrenme",
            "languages": "Diller",
            "selectLanguage": "Bir dil seçin",
            "selectLearningType": "Öğrenme türünü seçin",
            "words": "Kelimeler",
            "selectType": "Bir tür seçin",
            "animals": "Hayvanlar",
            "colors": "Renkler",
            "fruits": "Meyveler",
            "vegetables": "Sebzeler",
            "foods": "Gıdalar",
            "verbs": "Fiiller",
            "houses": "Ev",
            "schools": "Okul",
            "supplies": "Okul malzemeleri",
            "natures": "Doğa",
            "places": "Yerler ve Ulaşım",
            "occupations": "Meslekler",
            "iWantToLearn" : "Öğrenmek istiyorum"
        }
    },
    ru: {
        translation: {
            "story": "история",
            "riddle": "задача",
            "joke": "шутка",
            "mediaSource": "Чтобы запросить {{kind}}",
            "useKeyboard": "Я пишу на клавиатуре",
            "useMicrophone": "Я говорю в микрофон",
            "usedrawing": "Я сканирую свой рисунок",
            "tellStory": "Расскажи мне {{kind}} о",
            "chooseSourceType": "Выберите",
            "sayAndClick": "Скажите, что вы хотите, затем нажмите сюда",
            "takePhotoAndClick": "Сфотографируй свой рисунок и нажми сюда",
            "start": "Нажмите здесь, чтобы начать",
            "send": "Отправить",
            "wildAnimals": "Пример: Дикие животные",
            "home": "Главная",
            "myChannel": "Мой канал",
            "create": "Создать",
            "subscriptions": "Подписки",
            "hideSolution": "Скрыть решение",
            "showSolution": "Показать решение",
            "makeVideoStory": "Я создаю свой волшебный фильм",
            "makeAudioStory": "Я разрабатываю свою собственную аудио-историю",
            "startFunChallenge": "Я решаю таинственные головоломки",
            "craftMyJokes": "Я создаю свой мир шуток",
            "watchStories": "Я погружаюсь в свои фильмы",
            "listenToStories": "Я открываю для себя свои чудесные истории",
            "funChallenges": "Я принимаю свои фантастические вызовы",
            "exploreMyJokes": "Я исследую свою вселенную шуток",
            "storyInPreparation": "История в подготовке... Почему бы и нет",
            "listenAnotherStory": "послушать другую историю",
            "watchAnotherStory": "посмотреть другую историю",
            "playARiddle": "играть в загадку",
            "giggleWithAnotherJoke": "Хихикать с другой шуткой",
            "pending": "в ожидании",
            "myAccount": "Мой аккаунт",
            "signOut": "Выйти",
            "signInWithGoogle": "Войти через Google",
            "featureNotYetAvailable": "Функция пока недоступна",
            "title": "Название:",
            "yearsOld": "лет",
            "im": "Мне",
            "show": "Показать",
            "hide": "Скрыть",
            "showStory": "Показать текст истории",
            "hideStory": "Скрыть текст истории",
            "surpriseMe": "Удиви меня",
            "chooseForMe":"",
            "homeTitle": "Привет, друзья! Добро пожаловать в наше образовательное приложение!",
            "homeText": "Здесь ты - художник! Создавай свои собственные истории на видео или аудио.<br /><br />Задавай загадки и делись шутками, чтобы смеяться со своими друзьями.<br /><br />Ты даже можешь использовать свой рисунок, чтобы создать историю.<br /><br />А все твои творения? Мы бережно их храним, чтобы ты мог посмотреть их в любое время.<br /><br />Готов к приключениям? Пойдем, узнаем, что ты сможешь создать сегодня!",
            "english":"Английский",
            "spanish":"Испанский",
            "arabic":"Арабский",
            "french":"Французский",
            "german":"Немецкий",
            "chinese":"Китайский",
            "japanese":"Японский",
            "hindi":"Хинди",
            "turkish":"Турецкий",
            "korean":"Корейский",
            "italian":"Итальянский",
            "dutch":"Голландский",
            "russian":"Русский",
            "indonesian":"Индонезийский",
            "alphabet": "Алфавит",
            "numbers": "Числа",
            "learning": "Обучение",
            "languages": "Языки",
            "selectLanguage": "Выберите язык",
            "selectLearningType": "Выберите тип обучения",
            "words": "Cлова",
            "selectType": "Выберите тип",
            "animals": "Животные",
            "colors": "Цвета",
            "fruits": "Фрукты",
            "vegetables": "овощи",
            "foods": "Еда",
            "verbs": "Глаголы",
            "houses": "Дом",
            "schools": "Школа",
            "supplies": "Школьные принадлежности",
            "natures": "Природа",
            "places": "Места и Транспорт",
            "occupations": "Профессии",
            "iWantToLearn" : "Я хочу учиться"
        }
    },
    id: {
        translation: {
            "story": "sebuah cerita",
            "riddle": "tantangan",
            "joke": "lelucon",
            "mediaSource": "Untuk meminta {{kind}}",
            "useKeyboard": "Saya mengetik di keyboard",
            "useMicrophone": "Saya berbicara di mikrofon",
            "usedrawing": "Saya memindai gambar saya",
            "tellStory": "Ceritakan {{kind}} tentang",
            "chooseSourceType": "Pilih",
            "sayAndClick": "Katakan apa yang Anda inginkan lalu klik di sini",
            "takePhotoAndClick": "Ambil foto gambarmu dan klik di sini",
            "start": "Klik di sini untuk memulai",
            "send": "Kirim",
            "wildAnimals": "Contoh: Hewan liar",
            "home": "Beranda",
            "myChannel": "Saluran saya",
            "create": "Buat",
            "subscriptions": "Langganan",
            "hideSolution": "Sembunyikan solusi",
            "showSolution": "Tampilkan solusi",
            "makeVideoStory": "Saya membuat film ajaib saya",
            "makeAudioStory": "Saya merancang cerita audio saya sayAndClickiri",
            "startFunChallenge": "Saya menyelesaikan teka-teki misterius",
            "craftMyJokes": "Saya menciptakan dunia lelucon saya sendiri",
            "watchStories": "Saya menyelam ke dalam film saya",
            "listenToStories": "Saya menemukan cerita ajaib saya",
            "funChallenges": "Saya menghadapi tantangan fantastis saya",
            "exploreMyJokes": "Saya menjelajahi alam semesta lelucon saya",
            "storyInPreparation": "Cerita dalam persiapan... Mengapa tidak",
            "listenAnotherStory": "dengarkan cerita lain",
            "watchAnotherStory": "tonton cerita lain",
            "playARiddle": "mainkan teka-teki",
            "giggleWithAnotherJoke": "Tertawa dengan lelucon lainnya",
            "pending": "tunda",
            "myAccount": "Akun Saya",
            "signOut": "Keluar",
            "signInWithGoogle": "Masuk dengan Google",
            "featureNotYetAvailable": "Fitur belum tersedia",
            "title": "Judul:",
            "yearsOld": "tahun",
            "im": "Saya berumur",
            "show": "Tampilkan",
            "hide": "Sembunyikan",
            "showStory": "Tampilkan Teks Cerita",
            "hideStory": "Sembunyikan Teks Cerita",
            "surpriseMe": "Kejutkan saya",
            "chooseForMe":"",
            "homeTitle": "Hai teman! Selamat datang di aplikasi pendidikan kami!",
            "homeText": "Di sini, kamu adalah seniman! Buat ceritamu sendiri dalam video atau audio.<br /><br />Buat teka-teki dan bagikan lelucon untuk tertawa bersama temanmu.<br /><br />Kamu bahkan bisa menggunakan gambar yang telah kamu buat untuk membuat cerita.<br /><br />Dan semua karya ciptamu? Kami menyimpannya dengan hati-hati agar kamu bisa melihatnya kapan saja.<br /><br />Siap untuk petualangan? Ayo, temukan apa yang bisa kamu ciptakan hari ini!",
            "english":"Inggris",
            "spanish":"Spanyol",
            "arabic":"Arab",
            "french":"Perancis",
            "german":"Jerman",
            "chinese":"Cina",
            "japanese":"Jepang",
            "hindi":"Hindi",
            "turkish":"Turki",
            "korean":"Korea",
            "italian":"Italia",
            "dutch":"Belanda",
            "russian":"Rusia",
            "indonesian":"Indonesia",
            "alphabet": "Abjad",
            "numbers": "Nomor",
            "learning": "Pembelajaran",
            "languages": "Bahasa",
            "selectLanguage": "Pilih bahasa",
            "selectLearningType": "Pilih jenis belajar",
            "words": "Kata-kata",
            "selectType": "Pilih tipe",
            "animals": "Hewan",
            "colors": "Warna",
            "fruits": "Buah",
            "vegetables": "Sayuran",
            "foods": "Makanan",
            "verbs": "Kata Kerja",
            "houses": "Rumah",
            "schools": "Sekolah",
            "supplies": "Perlengkapan sekolah",
            "natures": "Alam",
            "places": "Tempat & Transportasi",
            "occupations": "Pekerjaan",
            "iWantToLearn" : "Saya ingin belajar"
        }
    }
};

let defaultLang = localStorage.getItem('language');

if (!defaultLang) {
    defaultLang = navigator.language.slice(0, 2) || 'en';
    localStorage.setItem('language', defaultLang);
}

console.log('defaultLang : ', defaultLang);

i18n
    .use(Backend)
    .use(initReactI18next) // passe l'instance de i18next à react-i18next.
    .init({
        resources,
        lng: defaultLang,
        fallbackLng: 'en',
        debug: true,
        backend: {
            loadPath: '/locales/{{lng}}.json',
        },
        interpolation: {
            escapeValue: false // react déjà sécurisé.
        },
        ns: ['translation'],
        defaultNS: 'translation',
    });

export default i18n;
