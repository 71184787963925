import React, {useContext, useEffect, useRef, useState} from "react";
import axiosInstance from './Axios';
import jwtDecode from "jwt-decode";
import ReactPlayer from "react-player";
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";
import {ShakeButtonContext} from "./ShakeButtonContext";
import {
    AudioPlayerContainer,
    AudioPlayerWrapper,
    PlayerWrapper,
    StyledAudioReactPlayer,
    StyledReactPlayer
} from './CardStyles';
import {CircularProgress} from "@mui/material";
import {TitleHeading, VideoCard} from "./TextToMedia";
import {checkDisableButton, incrementCredit} from "./localStorageUtils";

const ResponsivePlayerWrapper = styled.div`
    position: relative;
    padding-top: 56.25%; /* Ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
    overflow: hidden;
`;

const ResponsivePlayer = styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    @media (min-width: 600px) {
        flex-direction: row;
    }
`;

const Button = styled.button`
    font-size: 18px;
    margin-bottom: 10px;
    padding: 10px 20px;
    background-color: #4a148c;  // purple background
    color: white;  // white text
    border: none;
    border-radius: 10px;  // rounded border
    font-family: 'Comic Neue', cursive, sans-serif;
    cursor: pointer;  // change cursor on hover
    transition: background-color 0.3s;  // smooth color transition

    // change background color on hover
    &:hover {
        background-color: #311b92;  // darker purple
    }

    &:last-child {
        margin-bottom: 0;  // remove margin from the last button
    }

    @media (min-width: 600px) {
        margin-right: 10px;
        margin-bottom: 0;
    }
    
    &:disabled {
        background-color: #757575;  // grey background when disabled
        cursor: not-allowed;  // change cursor when disabled
    }
`;

const ResultContainer = styled.div`
    margin-top: 20px;
    width: 90%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
`;

const ResultHeading = styled.h2`
    font-size: 24px;
    color: #4a148c;  // purple
    font-family: 'Comic Neue', cursive, sans-serif;
`;

const ResultText = styled.p`
    font-size: 20px;
    color: #4a148c;  // purple
    font-family: 'Comic Neue', cursive, sans-serif;
`;

const StorySolution = styled.div`
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    max-height: 200px;
    overflow: auto;
    font-size: 20px;
    color: #4a148c;
    font-family: 'Comic Neue', cursive, sans-serif;
    max-width: 100%; 
    white-space: pre-wrap;

    @media (max-width: 600px) {
        width: 100%; // Changer à 100%
    }
`;

const SolutionContainer = styled.div`
    max-height: 100px;
    overflow: auto;

    padding-top: 10px;
    @media (max-width: 600px) {
        max-height: 50vh; // Adjust the max-height for mobile devices
        padding-top: 5px;
    }
`;

const SolutionContainerWait = styled.div`
    max-height: 50px;
    overflow: auto;

    padding-top: 5px;
    @media (max-width: 600px) {
        max-height: 25vh; // Adjust the max-height for mobile devices
        padding-top: 3px;
    }
`;

const StoryButton = styled.button`
    font-size: 18px;
    padding: 10px 20px;
    background-color: #4a148c;
    color: white;
    border: none;
    border-radius: 10px;
    font-family: 'Comic Neue', cursive, sans-serif;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #e64a19;
    }

    @media (max-width: 600px) {
        width: 100%;
    }
`;

const StoryButtonWait = styled.button`
    font-size: 10px;
    padding: 2px 4px;
    background-color: #4a148c;
    color: white;
    border: none;
    border-radius: 10px;
    font-family: 'Comic Neue', cursive, sans-serif;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #e64a19;
    }

    @media (max-width: 600px) {
        width: 100%;
    }
`;

type JWTDeCode  = {
    sub: string,
    email: string,
    iat: number,
    exp: number
}

type MediaResult = {
    title: string;
    audio_url?: string;
    video_url?: string;
    image_url?: string;
};

const LoadingContainerWait = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: #FFD700;
  border-radius: 20px;
  padding: 10px;
  margin: 0 auto;
`;

const LoadingText = styled.p`
  font-family: 'Comic Neue', cursive, sans-serif;
  font-size: 14px;
  color: #151516;
  text-align: center;
  margin-top: 5px;
`;

type ImageToMediaProps = {
    mediaType: 'audio' | 'video';
    kind: 'story' | 'riddle' | 'joke';
}

type Audio = {
    audio_url: string,
    title: string,
    solution?: string
}

type Video = {
    video_url: string,
    image_url: string,
    title: string
}

const ImageToMedia: React.FC<ImageToMediaProps> = ({mediaType, kind}) => {
    const [executionArn, setExecutionArn] = useState<string | null>(null);
    const [status, setStatus] = useState<string | null>(null);
    const [result, setResult] = useState<MediaResult | null>(null);
    const [recording, setRecording] = useState(false);
    const [language, setLanguage] = useState('en');
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const [showSolution, setShowSolution] = useState(false);
    const [showSolutionWait, setShowSolutionWait] = useState<Record<number, boolean>>({});
    const { shakeButton } = useContext(ShakeButtonContext);

    const [audios, setAudios] = useState<Audio[]>([]);
    const [loadingAudios, setLoadingAudios] = useState<boolean>(false);
    const [loadingResult, setLoadingResult] = useState(false);
    const [videos, setVideos] = useState<Video[]>([]);
    const [buttonDisable, setButtonDisable] = useState<boolean>(false);

    const videoRef = useRef<HTMLVideoElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const { t } = useTranslation();

    const startRecording = () => {
        navigator.mediaDevices.getUserMedia({
            video: { facingMode: 'environment' }
        })
            .then(stream => {
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }
                setRecording(true);
            })
            .catch(error => console.error('Error accessing media devices.', error));
    };

    const fetchStories = async (user_id:string, token:string) => {
        try {
            let defaultLang = localStorage.getItem('language');
            const response = await axiosInstance.get('/audio-stories-wait/' + user_id, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    fileType: mediaType,
                    kind: kind,
                    language: defaultLang
                }
            });
            if (mediaType == "video") {
                setVideos(prevAudios => prevAudios.concat(response.data))
            } else {
                setAudios(prevAudios => prevAudios.concat(response.data));
            }

        } catch (error) {
            console.error("Error calling API Gateway:", error);
        } finally {
            // Set loadingAudios to false when done fetching
            setLoadingAudios(false);
        }
    };

    const stopRecording = () => {
        if (videoRef.current && canvasRef.current) {
            const video = videoRef.current;
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');

            if (video.srcObject && context) {
                context.drawImage(video, 0, 0, canvas.width, canvas.height);
                if (video.srcObject instanceof MediaStream) {
                    video.srcObject.getVideoTracks().forEach(track => track.stop());
                }

                canvas.toBlob(blob => {
                    if (blob !== null) {
                    const formData = new FormData();
                    formData.append('image', blob, 'image.png');

                    const token = localStorage.getItem('token');
                    if (token === null) {
                        console.error("No token found in localStorage.");
                        shakeButton();
                        return;
                    }

                    const decodedToken : JWTDeCode = jwtDecode(token);
                    const user_id = decodedToken.sub;
                    let defaultLang = localStorage.getItem('language');
                    let age = localStorage.getItem('age');
                    axiosInstance.post('/image-media/' + user_id, formData, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                        params: {
                            fileType: mediaType,
                            kind: kind,
                            defaultLang: defaultLang,
                            age: age
                        },
                    })
                        .then(response => {
                            console.log('Image sent to backend', response.data);
                            setExecutionArn(response.data); // Assume that executionArn is in the response data
                        })
                        .catch(error => {
                            console.error('Error sending image to backend', error);
                            // Set loadingAudios to false when got the result
                            setLoadingAudios(false);
                            setLoadingResult(false);
                            setButtonDisable(false);
                            return;
                        });
                    } else {
                        console.error('Error: Unable to create blob from canvas');
                    }
                });
            }
        }
        setRecording(false);
        setLoadingResult(true);

        // Set loadingAudios to true when start fetching audios
        setLoadingAudios(true);
        setButtonDisable(true);
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token === null) {
            console.error("No token found in localStorage.");
            shakeButton();
            return;
        }

        const decodedToken : JWTDeCode = jwtDecode(token);
        const user_id = decodedToken.sub;

        fetchStories(user_id, token);
    }, []); // Empty dependency array means this effect runs once on mount

    useEffect(() => {
        if (!executionArn) return;

        const token = localStorage.getItem('token');
        if (token === null) {
            console.error("No token found in localStorage.");
            shakeButton();
            return;
        }

        let cron = 61000;
        if (mediaType === "audio") {
            cron = 15000;
            if (kind === "riddle" || kind === "joke") {
                cron = 5000;
            }
        }
        const intervalId = setInterval(() => {
            // Reset audios list before making the request
            axiosInstance.get('/text-video-check/' + executionArn, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    fileType: mediaType
                },
            })
                .then(response => {
                    const data = response.data;
                    setStatus(data.status);
                    if (data.status === 'SUCCEEDED') {
                        setResult(data.output.result);
                        clearInterval(intervalId);
                        // Set loadingAudios to false when got the result
                        setLoadingAudios(false);
                        setLoadingResult(false);
                        setButtonDisable(false);
                        incrementCredit(kind, mediaType);
                    } else if (data.status === 'FAILED') {
                        console.error('Error: Execution failed');
                        clearInterval(intervalId);
                        // Set loadingAudios to false when got the result
                        setLoadingAudios(false);
                        setLoadingResult(false);
                        setButtonDisable(false);
                    }
                })
                .catch(error => {
                    console.error('Error checking status:', error);
                    clearInterval(intervalId);
                    setButtonDisable(false);
                });
        }, cron);

        return () => clearInterval(intervalId); // clean up the interval on unmount
    }, [executionArn]);

    return (
        <>
            <ButtonsContainer>
                <Button onClick={startRecording} hidden={recording} disabled={buttonDisable || checkDisableButton(kind, mediaType)}>{t('start')}</Button>
                <Button onClick={stopRecording} hidden={!recording}>{t('takePhotoAndClick')}</Button>
            </ButtonsContainer>

            <video ref={videoRef} autoPlay style={{ display: recording ? 'block' : 'none' }}></video>
            <canvas ref={canvasRef} width={640} height={480} style={{ display: recording ? 'none' : 'none' }}></canvas>

            {result && (
                <ResultContainer>
                    <ResultHeading>Result:</ResultHeading>
                    <ResultText>{kind !== 'riddle' && `${t('title')} ${result!.title}`}</ResultText>
                    <ResponsivePlayerWrapper>
                        <ResponsivePlayer
                            url={mediaType === 'audio' ? result!.audio_url : result!.video_url}
                            controls={true}
                            width="100%"
                            height="100%"
                            config={{
                                file: {
                                    attributes: {
                                        preload: 'auto',
                                    },
                                },
                            }}
                        />
                    </ResponsivePlayerWrapper>
                </ResultContainer>
            )}
            {(loadingResult || loadingAudios) &&
                <LoadingContainerWait>
                    <CircularProgress />
                    <LoadingText>
                        {`${t('storyInPreparation')} ${
                            kind === 'story'
                                ? mediaType === "audio"
                                    ? t('listenAnotherStory')
                                    : t('watchAnotherStory')
                                : kind === 'joke' ? t('giggleWithAnotherJoke') : t('playARiddle')
                        } ${t('pending')} ?`}
                    </LoadingText>
                </LoadingContainerWait>
            }

            {(loadingResult || loadingAudios) && audios.filter(audio => audio && audio.audio_url).map((audio, index) => (
                <AudioPlayerContainer key={index}>
                    <AudioPlayerWrapper>
                        <StyledAudioReactPlayer
                            url={audio.audio_url}
                            controls={true}
                            light={true}
                        />
                    </AudioPlayerWrapper>
                    {kind === 'riddle' &&
                        <>
                            <SolutionContainerWait>
                                {showSolutionWait[index] && <StorySolution>{audio.solution}</StorySolution>}
                                <StoryButtonWait onClick={() => setShowSolutionWait(prev => ({...prev, [index]: !prev[index]}))}>{showSolutionWait[index] ? t('hideSolution') : t('showSolution')}</StoryButtonWait>
                            </SolutionContainerWait>
                        </>
                    }
                </AudioPlayerContainer>
            ))}
            {(loadingResult || loadingAudios) && videos.filter(video => video && video.video_url).map((video, index) => (
                <VideoCard key={index}>
                    <TitleHeading>{video.title}</TitleHeading>
                    <PlayerWrapper>
                        <StyledReactPlayer
                            url={video.video_url}
                            light={video.image_url}
                            controls={true}
                            width="100%"
                            height="100%"
                            config={{
                                file: {
                                    attributes: {
                                        preload: 'auto',
                                    },
                                },
                            }}
                        />
                    </PlayerWrapper>
                </VideoCard>
            ))}
        </>
    );

};

export default ImageToMedia;
