import React, {useContext, useEffect, useRef, useState} from "react";
import axiosInstance from './Axios';
import jwtDecode from 'jwt-decode';
import ReactPlayer from 'react-player';
import styled from "@emotion/styled";
import {ShakeButtonContext} from "./ShakeButtonContext";

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-top: 5px;
`;
const Button = styled.button`
    font-size: 16px;  // Increase font size
    margin-right: 10px;
    padding: 10px 20px;
    background-color: #FFD700;  // Change to a bright color
    color: #000000;  // Change text color to black
    border: none;
    border-radius: 15px;  // Increase border radius
    font-family: 'Comic Neue', cursive, sans-serif;
    cursor: pointer;
    transition: background-color 0.3s;

    // Change background color on hover
    &:hover {
        background-color: #FFC700;  // Change to a different bright color
    }

    &:last-child {
        margin-right: 0;
    }
`;
const TitleHeading = styled.h2`
    font-size: 28px;  // Increase font size
    color: #4a148c;
    font-family: 'Comic Neue', cursive, sans-serif;
`;
const ResultHeading = styled.h2`
    font-size: 24px;
    color: #4a148c;
    font-family: 'Comic Neue', cursive, sans-serif;
`;

const AudioContainer = styled.div`
    margin-bottom: 20px;
    margin: 10px;  // Adds some space around each card
    padding: 20px;  // Adds some space inside each card
    border-radius: 15px;  // Rounded corners
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);  // Adds a shadow

    // Card style for the last card
   // &:last-child {
     //   margin-bottom: 100px;
   // }

    // Card style on hover
    &:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
        transition: box-shadow 0.3s ease-in-out;
    }
`;

const MainContainer = styled.div`
    padding-bottom: 100px;  // Adjust as needed
    padding-top: 70px;
    @media (max-width: 600px) {
        padding-top: 50px;
    }
`;


type Audio = {
    audio_url: string,
    title: string,
    solution?: string
}

const Challenges = () => {
    const [audios, setAudios] = useState<Audio[]>([]);
    const [showSolution, setShowSolution] = useState<{ [index: number]: boolean }>({});
    const [endOfData, setEndOfData] = useState<boolean>(false);
    const lastKeyRef = useRef(null);  // Utilisez useRef au lieu de useState
    const { shakeButton } = useContext(ShakeButtonContext);
    const loadingRef = useRef<HTMLDivElement>(null);  // Ref pour l'élément de chargement

    const observer = useRef(new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && !endOfData) {
            fetchAudios();  // Charge plus de vidéos lorsque l'élément de chargement est visible et que endOfData est false
        }
    }, {
        threshold: 1  // Déclenche l'observer lorsque 100% de l'élément est visible
    }));


    const fetchAudios = async () => {
        const token = localStorage.getItem('token');
        if (token === null) {
            console.error("No token found in localStorage.");
            shakeButton();
            return;
        }

        type JWTDeCode = {
            sub: string,
            email: string,
            iat: number,
            exp: number
        }

        const decodedToken: JWTDeCode = jwtDecode(token);
        const user_id = decodedToken.sub;

        try {
            const response = await axiosInstance.get('/challenges/' + user_id, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    startKey: lastKeyRef.current  // Utilisez lastKeyRef.current au lieu de lastKey
                },
            });
            setAudios(audios => audios.concat(response.data.audios_urls));  // ajoute les nouveaux audios à la liste existante
            lastKeyRef.current = response.data.last_evaluated_key;  // Mettez à jour lastKeyRef.current au lieu de lastKey

            setEndOfData(response.data.end_of_data);
            // Si endOfData est vrai, supprimez l'IntersectionObserver
            if (response.data.endOfData && loadingRef.current) {
                observer.current.unobserve(loadingRef.current);
            }

        } catch (error) {
            console.error("Error calling API Gateway:", error);
        }

    };

    useEffect(() => {
        fetchAudios().then(() => {
            const currentLoadingRef = loadingRef.current;
            const currentObserver = observer.current;

            if (currentLoadingRef) {
                currentObserver.observe(currentLoadingRef);
            }

            return () => {
                if (currentLoadingRef) {
                    currentObserver.unobserve(currentLoadingRef);
                }
            };
        });
    }, []);


    return (
        <MainContainer>
            {audios.map((audio, index) => (
                <AudioContainer key={index}>
                    <TitleHeading>{audio.title}</TitleHeading>
                    <ReactPlayer
                        url={audio.audio_url}
                        controls={true}
                        width="100%"
                        height="50px"
                        light={true}
                    />
                    {showSolution[index] && audio.solution && <ResultHeading>{audio.solution}</ResultHeading>}
                    <ButtonsContainer>
                        <Button onClick={() => setShowSolution({ ...showSolution, [index]: !showSolution[index] })}>
                            {showSolution[index] ? 'Masquer la solution' : 'Afficher la solution'}
                        </Button>
                    </ButtonsContainer>
                </AudioContainer>
            ))}
            {/* Affichez "Loading..." uniquement si endOfData est faux */}
            {!endOfData && <div ref={loadingRef}>Loading...</div>}

        </MainContainer>
    );
};

export default Challenges;
