import {useEffect, useState} from "react";
import TextToMedia from "./TextToMedia";
import AudioToMedia from "./AudioToMedia";
import ImageToMedia from "./ImageToMedia";
import styled from "@emotion/styled";
import {useTranslation} from 'react-i18next';
import i18n from "./i18n";
import SurpriseMeToMedia from "./SurpriseMeToMedia";

const Container = styled.div`
    display: flex;
    flex-direction: column;  
    align-items: flex-start; 
    width: 100%;  
    flex-wrap: wrap;
    const MainContainer = styled.div\`
    padding-bottom: 10px;  // Adjust as needed
    padding-top: 70px;
    @media (max-width: 600px) {
        align-items: center; 
        padding-top: 50px;
    }
`;

const Label = styled.label`
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #4a148c; // purple
    font-family: 'Comic Neue', cursive, sans-serif;
    margin-top: 20px;
`;

const Select = styled.select`
    margin-left: 4px;  
    font-size: 18px;
    padding: 5px;
    border-radius: 10px;  // rounded border
    border: 2px solid #4a148c; // purple border
    font-family: 'Comic Neue', cursive, sans-serif;

    @media (max-width: 600px) {
        margin-left: 0;
        width: 100%; 
    }
`;

type MediaCreatorProps = {
    mediaType: 'audio' | 'video';
    kind: 'story' | 'riddle' | 'joke';
    title: string;
}

const MediaCreator: React.FC<MediaCreatorProps> = ({mediaType, kind, title}) => {
    const [sourceType, setSourceType] = useState<'surpriseme' | 'text' | 'audio' | 'image' | null>(null);

    useEffect(() => {
        setSourceType(null); // réinitialiser sourceType à null chaque fois que le composant est monté
    }, [mediaType, kind, title]);

    const handleSourceTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSourceType(e.target.value as 'surpriseme' | 'text' | 'audio' | 'image');
    };

    const { t } = useTranslation();
    const currentLanguage = i18n.language;
    const isRtl = currentLanguage === 'ar';

    let ContentComponent;
    switch(sourceType) {
        case 'surpriseme':
            ContentComponent = <SurpriseMeToMedia mediaType={mediaType} kind={kind} />;
            break;
        case 'text':
            ContentComponent = <TextToMedia mediaType={mediaType} kind={kind} />;
            break;
        case 'audio':
            ContentComponent = <AudioToMedia mediaType={mediaType} kind={kind} />;
            break;
        case 'image':
            ContentComponent = <ImageToMedia mediaType={mediaType} kind={kind} />;
            break;
    }

    return (
        <Container style={{ direction: isRtl ? 'rtl' : 'ltr' }}>
            {/*<Heading>{title}</Heading>*/}
            <Label>
                {t('mediaSource', { kind: t(kind) })}
                <Select value={sourceType || ''} onChange={handleSourceTypeChange}>
                    <option value="">--{t('chooseSourceType')}--</option>
                    <option value="surpriseme">{t('surpriseMe')}</option>
                    <option value="text">{t('useKeyboard')}</option>
                    <option value="audio">{t('useMicrophone')}</option>
                    <option hidden={mediaType == 'audio' } value="image">{t('usedrawing')}</option>
                </Select>
            </Label>
            {ContentComponent}
        </Container>
    );
};

export default MediaCreator;
