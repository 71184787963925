import React, { useEffect, useState, useContext, useRef } from 'react';
import axiosInstance from './Axios';
import {ShakeButtonContext} from "./ShakeButtonContext";
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";

const MainContainer = styled.div`
    background-color: #fafad2;  // Fond de couleur claire
    color: #000080;  // Texte de couleur foncée pour un bon contraste
    font-family: 'Comic Sans MS', cursive, sans-serif;  // Police enfantine
    padding-bottom: 100px;  // Ajuster si nécessaire
    padding-top: 70px;
    @media (max-width: 600px) {
        padding-top: 50px;
    }
`;

const Select = styled.select`
    width: 200px;
    height: 40px;
    margin: 10px;
    border-radius: 5px;  // Coins arrondis
    border: 2px solid #000080;  // Bordure de couleur foncée
    font-size: 20px;  // Taille de police plus grande
    background-color: #f0f8ff;  // Fond de couleur claire
    @media (max-width: 600px) {
        width: 130px;
        height: 30px;
        font-size: 20px;
        margin: 5px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

const Button = styled.button`
    width: 60px;
    height: 60px;
    padding: 10px;
    margin: 10px;
    border-radius: 20%;  // Rounded corners for circular button look
    border: 2px solid #e8eaed;  // Dark colored border
    font-size: 24px;  // Larger font size
    text-align: center;
    background-color: #f0f8ff;  // Light colored background
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    color: red;
`;

const NumberString = styled.span`
    font-size: 12px;  // Smaller font size for secondary text
    color: black;  // Dark grey color for less emphasis
`;

const StyledImage = styled.img`
    width: 80%;
    max-width: 300px;
    height: auto;
    margin-bottom: 10px;

    @media (min-width: 768px) {
        width: 200px;
    }
`;

const ButtonImage = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border: none;
    margin: 10px;
    padding: 10px;
    cursor: pointer;
`;

const TitleItem = styled.div`
    font-size: 28px;
    color: #4a148c;
    font-family: 'Comic Neue', cursive, sans-serif;
`;

const TitleHeading = styled.div`
    font-size: 28px;
    color: #4a148c;
    font-family: 'Comic Neue', cursive, sans-serif;
    @media (max-width: 600px) {
       font-size: 20px;
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px; // Pour ajouter de l'espace entre les éléments lorsqu'ils se déroulent
    position: sticky;
    top: 0; // pour coller au haut de la page
    background-color: inherit; // pour maintenir le fond du header conforme au reste de la page
    z-index: 100; // pour s'assurer que le header est toujours en haut
`;


// Utiliser Select et Button dans votre rendu à la place de 'select' et 'button'


interface AlphabetItem {
    audio_url: string;
    title: string;
    number_str: string;
}

const LANGUAGE_OPTIONS = [
    'english',
    'spanish',
    'arabic',
    'french',
    'german',
    'chinese',
    'japanese',
    'hindi',
    'turkish',
    'korean',
    'italian',
    'dutch',
    'russian',
    'indonesian',
] as const; // "as const" makes TypeScript treat these strings as literals instead of the general string type
type LanguageOption = typeof LANGUAGE_OPTIONS[number]; // This will create a type alias for the languages

const LEARNING_TYPES = ['alphabet', 'numbers', 'words'] as const;
type LearningType = typeof LEARNING_TYPES[number]; // New type for the learning type

interface WordTypeItem {
    image_url: string;
    title: string;
    title_english: string;
    audio_url: string;
}

const WORD_TYPES = ['animals', 'colors', 'fruits', 'vegetables', 'foods', 'verbs', 'houses', 'natures', 'schools', 'supplies'] as const;
type WordType = typeof WORD_TYPES[number]; // New type for the word type


const Learning = () => {
    const { shakeButton } = useContext(ShakeButtonContext);
    const [alphabet, setAlphabet] = useState<AlphabetItem[]>([]);
    const [audioSrc, setAudioSrc] = useState<string | null>(null);
    const [language, setLanguage] = useState<LanguageOption>();
    const [learningTypeVisible, setLearningTypeVisible] = useState<boolean>(false);
    const [learningType, setLearningType] = useState<LearningType>();
    const [wordType, setWordType] = useState<WordType>();
    const [wordTypeVisible, setWordTypeVisible] = useState<boolean>(false);
    const [wordItems, setWordItems] = useState<WordTypeItem[]>([]); // New state for the word items

    const { t } = useTranslation();

    const fetchWords = async () => {
        const token = localStorage.getItem('token');
        if (token === null) {
            console.error("No token found in localStorage.");
            shakeButton();
            return;
        }
        const response = await axiosInstance.get(`/language-${wordType}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                language: language,
                type: `${learningType}/${wordType}`
            },
        });
        setWordItems(response.data.audios);
    };

    const fetchAlphabetOrNumbers = async () => {
        const token = localStorage.getItem('token');
        if (token === null) {
            console.error("No token found in localStorage.");
            shakeButton();
            return;
        }
        const response = await axiosInstance.get('/language-target', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                language: language,
                type: learningType
            },
        });
        setAlphabet(response.data.audios);
    }

    useEffect(() => {
        if (language && learningType) {
            if (learningType === 'words') {
                console.log('wordType : ', wordType);
                if(wordType) {
                    fetchWords();
                }
            } else {
                fetchAlphabetOrNumbers();
            }
        }
    }, [language, learningType, wordType]);

    const handleLanguageChange = (selectedLanguage: LanguageOption) => {
        setLanguage(selectedLanguage);
        setLearningType(undefined);
        setLearningTypeVisible(true);
        setAlphabet([]);
        setWordItems([]);
        setWordTypeVisible(false);
        setWordType(undefined);
    };

    const handleLearningTypeChange = (type: LearningType) => {
        setAlphabet([]);
        setWordType(undefined);
        setWordItems([]);
        setLearningType(type);
        if (type === 'words') {
            setWordTypeVisible(true);
        } else {
           // fetchAlphabetOrNumbers();
            setWordTypeVisible(false);
        }
    };

    const handleWordTypeChange = (type: WordType) => {
        console.log('type : ', type);
        setWordType(type);
        console.log('wordType : ', wordType);
        // fetchWords();
    };

    const playSound = (url: string) => {
        const audio = new Audio(url);
        audio.play();
    };

    return (
        <MainContainer>
            <HeaderContainer>
                <TitleHeading>{t('iWantToLearn')}</TitleHeading>
                <Select value={language} onChange={e => handleLanguageChange(e.target.value as LanguageOption)}>
                    <option value="">{t('languages')}</option>
                    <option value="english">{t('english')}</option>
                    <option value="spanish">{t('spanish')}</option>
                    <option value="arabic">{t('arabic')}</option>
                    <option value="french">{t('french')}</option>
                    <option value="german">{t('german')}</option>
                    <option value="chinese">{t('chinese')}</option>
                    <option value="japanese">{t('japanese')}</option>
                    <option value="hindi">{t('hindi')}</option>
                    <option value="turkish">{t('turkish')}</option>
                    <option value="korean">{t('korean')}</option>
                    <option value="italian">{t('italian')}</option>
                    <option value="dutch">{t('dutch')}</option>
                    <option value="russian">{t('russian')}</option>
                    <option value="indonesian">{t('indonesian')}</option>
                </Select>

                {learningTypeVisible &&
                    <Select
                        value={learningType || ""}
                        onChange={e => handleLearningTypeChange(e.target.value as LearningType)}
                    >
                        <option value="">{t('selectLearningType')}</option>
                        <>
                            {(!(language === 'chinese' || language === 'japanese')) && <option value="alphabet">{t('alphabet')}</option>}
                            <option value="numbers">{t('numbers')}</option>
                            <option value="words">{t('words')}</option>
                        </>
                    </Select>
                }

                {wordTypeVisible &&
                    <Select
                        value={wordType || ""}
                        onChange={e => handleWordTypeChange(e.target.value as WordType)}
                    >
                        <option value="">{t('selectType')}</option>
                        {WORD_TYPES.map(type => (
                            <option key={type} value={type}>{t(type)}</option>
                        ))}
                    </Select>
                }
            </HeaderContainer>

            <br/>
            <ButtonContainer style={ language === 'arabic' ? { direction: 'rtl' } : {} }>
                {learningType === 'words' ? wordItems.map((item, index) => (
                        <ButtonImage key={index} onClick={() => playSound(item.audio_url)}>

                            {wordType === 'colors' ?
                                <div style={{width: '100px', height: '100px', backgroundColor: item.title_english}} /> :
                                <StyledImage src={`/images/${wordType}/${item.title_english}.jpg`} alt={item.title} />
                            }

                            <TitleItem>{item.title}</TitleItem>
                        </ButtonImage>
                    )) :
                    alphabet.map((item, index) => (
                        <Button key={index} onClick={() => playSound(item.audio_url)}>
                            {item.title}
                            {item.number_str && <NumberString>{item.number_str}</NumberString>}
                        </Button>
                    ))
                }
            </ButtonContainer>

            {audioSrc && <audio src={audioSrc} autoPlay />}
        </MainContainer>
    );
};

export default Learning;
