import Avatar from "./Avatar";
import styled from "@emotion/styled";
import { css, keyframes } from '@emotion/react';
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {googleLogout, useGoogleLogin} from "@react-oauth/google";
import axiosInstance, {env} from "./Axios";
import jwtDecode from "jwt-decode";
import {useNavigate} from "react-router-dom";
import {ShakeButtonContext} from "./ShakeButtonContext";


const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    background-color: #FFC947;
    border-bottom: 3px solid #FF3D00;
    position: fixed;
    width: 100vw;
    @media (max-width: 768px) {
        
    }
`;

const Logo = styled.img`
    height: 60px;
    @media (max-width: 768px) {
        height: 40px;
    }
`;

const StyledSelect = styled.select`
    padding: 10px;
    font-size: 12px;
    border-radius: 10px;
    border: 2px solid #FF3D00;
    color: #FF3D00;
    @media (max-width: 768px) {
        font-size: 12px;
        padding: 5px;
    }
`;

const shakeAnimation = keyframes`
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
`;


const StyledButton = styled.button<{ shouldShake?: boolean }>`
  position: relative; // Ajouter ceci pour permettre le positionnement absolu de l'image
  display: flex;  
  align-items: center;  
  justify-content: center;  
  background-color: #FF3D00;
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 14px;
  cursor: pointer;
  transition-duration: 0.4s;
  padding: 10px 20px;
  &:hover {
      background-color: #FF6E40;
  }
  @media (max-width: 768px) {
      font-size: 12px;
      padding: 5px 5px;
  }

  animation: ${({ shouldShake }) =>
    shouldShake
        ? css`${shakeAnimation} 0.5s linear infinite`
        : "none"};
`;

const BannerImage = styled.img`
    max-width: 50%; /* Adjust this value according to your needs */
    max-height: 100%;
`;
const StyledLabel = styled.label`
    margin-left: 2px;
    margin-right: 2px;
    font-size: 14px;
`;

const AgeSelectContainer = styled.div<{ language: string }>`
    display: flex;
    align-items: center;
    direction: ${props => props.language === 'ar' ? 'rtl' : 'ltr'};
`;

const ArrowImage = styled.img`
    position: absolute;
    bottom: -65px; // Ajustez cela pour modifier la position de l'image. Ceci place l'image 20px en dessous du bouton
    left: 50%; // Centre l'image horizontalement par rapport au bouton
    transform: translateX(-50%); // Cela assure que l'image est centrée correctement
    width: 50px; // Ajustez cela pour modifier la taille de l'image
    height: auto; // Cela assure que l'image garde son ratio d'aspect
`;




type UserInfoType = {
    "user_id": string,
    "created_at": string,
    "given_name": string,
    "family_name": string,
    "email": string,
    "picture": string,
    "name": string
}

const Header: React.FC = () => {
    const [userInfo, setUserInfo] = useState<UserInfoType | null>(null);
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'en');
    const [selectedAge, setSelectedAge] = useState(localStorage.getItem('age') || '5');
    const { shouldShakeButton, setShouldShakeButton } = useContext(ShakeButtonContext);  // Extraire à partir du contexte
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const intervalId = setInterval(refreshAccessToken, 55 * 60 * 1000);
        getUserInfo();
        getCredit();
        return () => clearInterval(intervalId);
    }, []); // Passing an empty array as second argument triggers the callback in useEffect only after the initial render thus replicating `componentDidMount` lifecycle behaviour

    useEffect(() => {
        const storedUserInfo = localStorage.getItem('userInfo');
        if (storedUserInfo) {
            setUserInfo(JSON.parse(storedUserInfo));
        }
    }, []); // Pas de dépendance ici

    const changeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        i18n.changeLanguage(event.target.value);
        localStorage.setItem('language', event.target.value);
        setSelectedLanguage(event.target.value);
    };
    const changeAge = (event: React.ChangeEvent<HTMLSelectElement>) => {
        localStorage.setItem('age', event.target.value);
        setSelectedAge(event.target.value);
    };


    const refreshAccessToken = async () => {
        try {
            const refreshToken = localStorage.getItem('refresh_token');
            if (!refreshToken) throw new Error("No refresh token found.");

            let url = 'oauth2/v2/auth';
            if (env === 'dev') {
                url += '/dev';
            }
            console.log('url : ', url);
            const response = await axiosInstance.post(url, {}, {
                headers: {
                    Authorization: `Bearer ${refreshToken}`,
                },
                params: {
                    type: 'refresh'
                }
            });
            const tokens = response.data;
            localStorage.setItem('token', tokens.id_token);
            localStorage.setItem('access_token', tokens.access_token);
            localStorage.setItem('token_expiry', tokens.token_expiry);
            localStorage.setItem('current_time', tokens.current_time);
        } catch (error) {
            console.error("Error refreshing access token:", error);
        }
    };

    const handleSignOut = async () => {
        try {
            await googleLogout();
            localStorage.removeItem('token');
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('token_expiry');
            localStorage.removeItem('current_time');
            localStorage.removeItem('userInfo');
            setUserInfo(null);
            navigate('/');
        } catch (error) {
            console.error("Error during logout:", error);
        }
    };

    const getUserInfo = async () => {
        try {
            type JWTDeCode  = {
                sub: string,
                email: string,
                iat: number,
                exp: number
            }

            const id_token = localStorage.getItem('token');
            if (id_token === null) {
                console.error("No token found in localStorage.");
                return;
            }
            const decodedToken : JWTDeCode = jwtDecode(id_token);
            const user_id = decodedToken.sub;
            const response = await axiosInstance.get('/user-info/' + user_id, {
                headers: {
                    Authorization: `Bearer ${id_token}`,
                },
            })
                .catch((error) => {
                    if (error.response.status === 401) {
                        // Tentez de rafraîchir le token ici, puis refaites la requête
                    }
                });
            if (response) {
                // console.log(JSON.stringify(response.data));
                localStorage.setItem('userInfo', JSON.stringify(response.data));
                setUserInfo(response.data);
            }
        } catch (error) {
            console.error("Error calling API Gateway:", error);
        }
    };

    const getCredit = async () => {
        try {
            type JWTDeCode  = {
                sub: string,
                email: string,
                iat: number,
                exp: number
            }

            const id_token = localStorage.getItem('token');
            if (id_token === null) {
                console.error("No token found in localStorage.");
                return;
            }
            const decodedToken : JWTDeCode = jwtDecode(id_token);
            const user_id = decodedToken.sub;
            const response = await axiosInstance.get('/credit/' + user_id, {
                headers: {
                    Authorization: `Bearer ${id_token}`,
                },
            })
                .catch((error) => {
                    if (error.response.status === 401) {
                        // Tentez de rafraîchir le token ici, puis refaites la requête
                    }
                });
            if (response) {
                // console.log(JSON.stringify(response.data));
                localStorage.setItem('credit', JSON.stringify(response.data));
                setUserInfo(response.data);
            }
        } catch (error) {
            console.error("Error calling API Gateway:", error);
        }
    };

    const handleSuccess = async (codeResponse: any) => {
        // Envoyez le code d'autorisation au serveur pour échanger contre des tokens d'accès et de rafraîchissement
        try {
            let url = 'oauth2/v2/auth';
            if (env === 'dev') {
                url += '/dev';
            }
            console.log('url : ', url);
            const tokenResponse = await axiosInstance.post(url, {}, {
                headers: {
                    Authorization: `Bearer ${codeResponse}`,
                },
                params: {
                    type: 'code'
                }
            });
            const tokens = tokenResponse.data;
            localStorage.setItem('token', tokens.id_token);
            localStorage.setItem('access_token', tokens.access_token);
            localStorage.setItem('refresh_token', tokens.refresh_token);
            localStorage.setItem('token_expiry', tokens.token_expiry);
            localStorage.setItem('current_time', tokens.current_time);

            getUserInfo();
            getCredit();
        } catch (error) {
            console.error("Error calling API Gateway:", error);
        }
    };

    const login = useGoogleLogin({
        onSuccess: codeResponse => {
            handleSuccess(codeResponse.code);
        },
        flow: 'auth-code',
    });

    return (
            <HeaderContainer>
                <Logo src="/images/logo.png" alt="Tell me" />
                <StyledSelect value={selectedLanguage} onChange={changeLanguage}>
                    <option value="en">English</option>
                    <option value="es">Español</option>
                    <option value="ar">العربية</option>
                    <option value="de">Deutsch</option>
                    <option value="fr">Français</option>
                    <option value="zh">中文</option>
                    <option value="hi">हिन्दी</option>
                    <option value="ja">日本語</option>
                    <option value="it">Italiano</option>
                    <option value="ko">한국어</option>
                    <option value="nl">Nederlands</option>
                    <option value="tr">Türkçe</option>
                    <option value="ru">Русский</option>
                    <option value="id">Bahasa</option>
                </StyledSelect>
                <AgeSelectContainer language={selectedLanguage}>
                    <StyledLabel>{t('im')}</StyledLabel>
                    <StyledSelect value={selectedAge} onChange={changeAge}>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">+15</option>
                    </StyledSelect>
                    <StyledLabel>{t('yearsOld')}</StyledLabel>
                </AgeSelectContainer>
                {userInfo ? (
                    <Avatar userImage={userInfo?.picture ?? null} userName={userInfo?.name ?? null} setUserInfo={setUserInfo} userInfo={userInfo} handleSignOut={handleSignOut} />
                ) : (
                    <StyledButton shouldShake={shouldShakeButton} onClick={() => login()}>
                        {t('signInWithGoogle')}
                        <ArrowImage src="/images/arrow.gif" alt="Arrow"/>
                    </StyledButton>
                )}
            </HeaderContainer>
    );
};

export default Header;
