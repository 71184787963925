import React, {ReactNode, useState} from 'react';
import styled from '@emotion/styled';
import {Link} from "react-router-dom";
import {Menu, MenuItem} from "@mui/material";
import {useTranslation} from "react-i18next";
import { TFunction } from "i18next";

// styled-components pour le Footer
const FooterContainer = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #FFC947;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-top: 3px solid #FF3D00;
    padding-top: 5px;
    padding-bottom: 5px;
    @media (max-width: 768px) {
    }
`;

const FooterItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const FooterLabel = styled.span`
    font-size: 12px;
    color: #FF3D00;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

interface DisabledLinkWrapperProps {
    t: TFunction;
    children: ReactNode;
    [key: string]: any;
}
const DisabledLinkWrapper: React.FC<DisabledLinkWrapperProps> = ({ t, children, ...rest }) => {
    const DisabledLink = styled.div`
    position: relative;
    cursor: not-allowed;

    &::after {
      content: "${t('featureNotYetAvailable')}";
      position: absolute;
      display: none;
      background: #000;
      color: #fff;
      padding: 5px;
      border-radius: 5px;
      bottom: 100%;
      right: 50%;
      transform: translateX(50%);
      white-space: nowrap;
    }

    &:hover::after {
      display: block;
    }
  `;

    return <DisabledLink {...rest}>{children}</DisabledLink>;
};

const DisableFooterLabel = styled.span`
    font-size: 12px;
    color: gray;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const Footer: React.FC = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [createAnchorEl, setCreateAnchorEl] = useState<null | HTMLElement>(null);
    const [learningAnchorEl, setLearningAnchorEl] = useState<null | HTMLElement>(null);

    const { t } = useTranslation();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCreateClick = (event: React.MouseEvent<HTMLElement>) => {
        setCreateAnchorEl(event.currentTarget);
    };

    const handleLearningClick = (event: React.MouseEvent<HTMLElement>) => {
        setLearningAnchorEl(event.currentTarget);
    };

    const handleCreateClose = () => {
        setCreateAnchorEl(null);
    };

    const handleLearningClose = () => {
        setLearningAnchorEl(null);
    };

    let isDisabled = false;
    return (
        <FooterContainer>
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                <FooterItem>
                    <i className="material-icons">home</i>
                    <FooterLabel>{t('home')}</FooterLabel>
                </FooterItem>
            </Link>
            <FooterItem>
                <i className="material-icons" onClick={handleClick}>video_library</i>
                <FooterLabel>{t('myChannel')}</FooterLabel>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClose} component={Link} to="/my-video-stories">{t('watchStories')}</MenuItem>
                    <MenuItem onClick={handleClose} component={Link} to="/my-audio-stories">{t('listenToStories')}</MenuItem>
                    <MenuItem onClick={handleClose} component={Link} to="/my-challenges">{t('funChallenges')}</MenuItem>
                    <MenuItem onClick={handleClose} component={Link} to="/my-jokes">{t('exploreMyJokes')}</MenuItem>
                </Menu>
            </FooterItem>
            <FooterItem>
                <i className="material-icons" onClick={handleCreateClick}>add_circle</i>
                <FooterLabel>{t('create')}</FooterLabel>
                <Menu
                    id="create-menu"
                    anchorEl={createAnchorEl}
                    keepMounted
                    open={Boolean(createAnchorEl)}
                    onClose={handleCreateClose}
                >
                    <MenuItem onClick={handleCreateClose} component={Link} to="/create-video-story">{t('makeVideoStory')}</MenuItem>
                    <MenuItem onClick={handleCreateClose} component={Link} to="/create-audio-story">{t('makeAudioStory')}</MenuItem>
                    <MenuItem onClick={handleCreateClose} component={Link} to="/create-challenge">{t('startFunChallenge')}</MenuItem>
                    <MenuItem onClick={handleCreateClose} component={Link} to="/create-joke">{t('craftMyJokes')}</MenuItem>
                </Menu>
            </FooterItem>
            <FooterItem>
                <i className="material-icons" onClick={handleLearningClick}>school</i>
                <FooterLabel>{t('learning')}</FooterLabel>
                <Menu
                    id="simple-menu"
                    anchorEl={learningAnchorEl}
                    keepMounted
                    open={Boolean(learningAnchorEl)}
                    onClose={handleLearningClose}
                >
                    <MenuItem onClick={handleLearningClose} component={Link} to="/learning">{t('languages')}</MenuItem>
                </Menu>
            </FooterItem>

        </FooterContainer>
    );
};

export default Footer;
