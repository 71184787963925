import React, {useContext, useEffect, useRef, useState} from "react";
import axiosInstance from './Axios';
import jwtDecode from 'jwt-decode';
import ReactPlayer from 'react-player';
import styled from "@emotion/styled";
import {ShakeButtonContext} from "./ShakeButtonContext";

export const VideoCard = styled.div`
    width: 80%; // Ajouter cette ligne pour réduire la largeur de la carte à 80% de la largeur du parent
    margin: 0 auto; // Centrer la carte
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 20px;

    &:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
        transition: box-shadow 0.3s ease-in-out;
    }
    @media (max-width: 600px) {
        width: 100%;
        margin: none;
    }
`;

const PlayerWrapper = styled.div`
    position: relative;
    padding-top: 56.25%;
`;

const StyledReactPlayer = styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;
    
    
`;

export const TitleHeading = styled.h2`
    font-size: 28px;
    color: #4a148c;
    font-family: 'Comic Neue', cursive, sans-serif;
    margin: 10px;
`;

const MainContainer = styled.div`
    padding-bottom: 100px;  // Adjust as needed
    padding-top: 70px;
    @media (max-width: 600px) {
        padding-top: 50px;
    }
`;

type Video = {
    video_url: string,
    image_url: string,
    title: string
}

const VideoStories = () => {
    const [videos, setVideos] = useState<Video[]>([]);
    const [endOfData, setEndOfData] = useState<boolean>(false);
    const lastKeyRef = useRef(null);  // Utilisez useRef au lieu de useState
    const { shakeButton } = useContext(ShakeButtonContext);
    const loadingRef = useRef<HTMLDivElement>(null);  // Ref pour l'élément de chargement

    const observer = useRef(new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && !endOfData) {
            fetchVideos();  // Charge plus de vidéos lorsque l'élément de chargement est visible et que endOfData est false
        }
    }, {
        threshold: 1  // Déclenche l'observer lorsque 100% de l'élément est visible
    }));

    const fetchVideos = async () => {
        const token = localStorage.getItem('token');
        if (token === null) {
            console.error("No token found in localStorage.");
            shakeButton();
            return;
        }

        type JWTDeCode  = {
            sub: string,
            email: string,
            iat: number,
            exp: number
        }

        const decodedToken : JWTDeCode = jwtDecode(token);
        const user_id = decodedToken.sub;

        try {
            const response = await axiosInstance.get( '/video-stories/' + user_id, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    startKey: lastKeyRef.current  // Utilisez lastKeyRef.current au lieu de lastKey
                },
            });
            setVideos(videos => videos.concat(response.data.video_urls));  // ajoute les nouveaux audios à la liste existante
            lastKeyRef.current = response.data.last_evaluated_key;  // Mettez à jour lastKeyRef.current au lieu de lastKey

            setEndOfData(response.data.end_of_data);
            // Si endOfData est vrai, supprimez l'IntersectionObserver
            if (response.data.endOfData && loadingRef.current) {
                observer.current.unobserve(loadingRef.current);
            }
        } catch (error) {
            console.error("Error calling API Gateway:", error);
        }
    };

    useEffect(() => {
        fetchVideos().then(() => {
            const currentLoadingRef = loadingRef.current;
            const currentObserver = observer.current;

            if (currentLoadingRef) {
                currentObserver.observe(currentLoadingRef);
            }

            return () => {
                if (currentLoadingRef) {
                    currentObserver.unobserve(currentLoadingRef);
                }
            };
        });
    }, []);


    return (
        <MainContainer>
            {videos.map((video, index) => (
                <VideoCard key={index}>
                    <TitleHeading>{video.title}</TitleHeading>
                    <PlayerWrapper>
                        <StyledReactPlayer
                            url={video.video_url}
                            light={video.image_url}
                            controls={true}
                            width="100%"
                            height="100%"
                            config={{
                                file: {
                                    attributes: {
                                        preload: 'auto',
                                    },
                                },
                            }}
                        />
                    </PlayerWrapper>
                </VideoCard>
            ))}
            {/* Affichez "Loading..." uniquement si endOfData est faux */}
            {!endOfData && <div ref={loadingRef}>Loading...</div>}
        </MainContainer>
    );
};

export default VideoStories;
