import React, {useState} from "react";
import {GoogleOAuthProvider} from '@react-oauth/google';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import ReactDOM from "react-dom";
import Footer from "./Footer";
import VideoStories from "./VideoStories";
import AudioStories from "./AudioStories";
import Challenges from "./Challenges";
import MediaCreator from "./MediaCreator";
import Subscriptions from "./Subscriptions";
import Header from "./Header";
import { ShakeButtonContext } from "./ShakeButtonContext";
import Jokes from "./Jokes";
import Home from "./Home";
import Learning from "./Learning";

const clientId = process.env.REACT_APP_CLIENT_ID || '606303163032-qihpuc49c8habhivuh4tf06olpq6t1qf.apps.googleusercontent.com';

const App = () => {

    const [shouldShakeButton, setShouldShakeButton] = useState(false);

    const shakeButton = () => {
        setShouldShakeButton(true);
        setTimeout(() => setShouldShakeButton(false), 10000);  // Stop shaking after 1 second
    }

    return (
        <GoogleOAuthProvider clientId={clientId}>
            <ShakeButtonContext.Provider value={{ shouldShakeButton, setShouldShakeButton, shakeButton }}>
            <Router>
                <div>
                    <Header />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/my-video-stories" element={<VideoStories />} />
                        <Route path="/my-audio-stories" element={<AudioStories kind='story' />} />
                        <Route path="/my-challenges" element={<Challenges />} />
                        <Route path="/my-jokes" element={<Jokes kind='joke' />} />

                        <Route path="/create-video-story" element={<MediaCreator mediaType='video' kind='story' title="Make a Video Story"/>} />
                        <Route path="/create-audio-story" element={<MediaCreator mediaType='audio' kind='story' title="Make an Audio Story"/>} />
                        <Route path="/create-challenge" element={<MediaCreator mediaType='audio' kind='riddle' title="Start a Fun Riddle"/>} />
                        <Route path="/create-joke" element={<MediaCreator mediaType='audio' kind='joke' title="Start a Fun Joke"/>} />

                        <Route path="/learning" element={<Learning />} />
                    </Routes>
                    <Footer />
                </div>
            </Router>
            </ShakeButtonContext.Provider>
        </GoogleOAuthProvider>
    );
};

ReactDOM.render(<App />, document.getElementById('root'));
export default App;
