import React, {useContext, useEffect, useRef, useState} from "react";
import axiosInstance from './Axios';
import jwtDecode from 'jwt-decode';
import ReactPlayer from 'react-player';
import {ShakeButtonContext} from "./ShakeButtonContext";
import { keyframes, css } from "@emotion/react";
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";

export const StoryButton = styled.button`
    font-size: 18px;
    padding: 10px 20px;
    background-color: #4a148c;
    color: white;
    border: none;
    border-radius: 10px;
    font-family: 'Comic Neue', cursive, sans-serif;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #e64a19;
    }

    @media (max-width: 600px) {
        width: 100%;
    }
    
`;

const TitleHeading = styled.h2`
    font-size: 28px;
    color: #4a148c;
    font-family: 'Comic Neue', cursive, sans-serif;
`;

const AudioContainer = styled.div`
    margin-bottom: 20px;
    margin: 10px;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);

    &:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
        transition: box-shadow 0.3s ease-in-out;
    }
`;

const MainContainer = styled.div`
    padding-bottom: 100px;  // Adjust as needed
    padding-top: 70px;
    @media (max-width: 600px) {
        padding-top: 50px;
    }
`;

// story
const slideDown = keyframes`
    0% { max-height: 0; }
    100% { max-height: 100vh; }
`;

const slideUp = keyframes`
    0% { max-height: 100vh; }
    100% { max-height: 0; }
`;

type StoryTextProps = {
    open: boolean;
};

const StoryText = styled.div<StoryTextProps>`
  overflow: hidden;
  ${({ open }) =>
    open
        ? css`
          max-height: 100vh;
          animation: ${slideDown} 0.5s forwards;
        `
        : css`
          max-height: 0;
          animation: ${slideUp} 0.5s forwards;
        `}
`;

type Audio = {
    audio_url: string,
    title: string,
    story: string
}

type MediaCreatorProps = {
    kind: 'story' | 'riddle' | 'joke';
}

const AudioStories : React.FC<MediaCreatorProps> = ({kind}) => {
    const [audios, setAudios] = useState<Audio[]>([]);
    const [endOfData, setEndOfData] = useState<boolean>(false);
    const [playingAudioIndex, setPlayingAudioIndex] = useState<number | null>(null); // Add this state
    const [openIndexes, setOpenIndexes] = useState<number[]>([]);

    const lastKeyRef = useRef(null);  // Utilisez useRef au lieu de useState
    const { shakeButton } = useContext(ShakeButtonContext);
    const loadingRef = useRef<HTMLDivElement>(null);  // Ref pour l'élément de chargement

    const { t } = useTranslation();

    const observer = useRef(new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && !endOfData) {
            fetchAudios();  // Charge plus de vidéos lorsque l'élément de chargement est visible et que endOfData est false
        }
    }, {
        threshold: 1  // Déclenche l'observer lorsque 100% de l'élément est visible
    }));

    const onPlay = (index: number) => {
        setPlayingAudioIndex(index);
    }

    const toggleOpen = (index: number) => {
        setOpenIndexes(prevOpenIndexes => {
            if (prevOpenIndexes.includes(index)) {
                return prevOpenIndexes.filter(i => i !== index);
            } else {
                return [...prevOpenIndexes, index];
            }
        });
    };

    const fetchAudios = async () => {
        const token = localStorage.getItem('token');
        if (token === null) {
            console.error("No token found in localStorage.");
            shakeButton();
            return;
        }

        type JWTDeCode  = {
            sub: string,
            email: string,
            iat: number,
            exp: number
        }

        const decodedToken : JWTDeCode = jwtDecode(token);
        const user_id = decodedToken.sub;

        try {
            const response = await axiosInstance.get('/audio-stories/' + user_id, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    startKey: lastKeyRef.current, // Utilisez lastKeyRef.current au lieu de lastKey
                    kind: kind,
                },
            });
            setAudios(audios => audios.concat(response.data.audios_urls));
            lastKeyRef.current = response.data.last_evaluated_key;  // Mettez à jour lastKeyRef.current au lieu de lastKey

            setEndOfData(response.data.end_of_data);
            // Si endOfData est vrai, supprimez l'IntersectionObserver
            if (response.data.endOfData && loadingRef.current) {
                observer.current.unobserve(loadingRef.current);
            }
        } catch (error) {
            console.error("Error calling API Gateway:", error);
        }
    };

    useEffect(() => {
        fetchAudios().then(() => {
            const currentLoadingRef = loadingRef.current;
            const currentObserver = observer.current;

            if (currentLoadingRef) {
                currentObserver.observe(currentLoadingRef);
            }

            return () => {
                if (currentLoadingRef) {
                    currentObserver.unobserve(currentLoadingRef);
                }
            };
        });
    }, []);

    return (
        <MainContainer>
            {audios.map((audio, index) => (
                <AudioContainer key={index}>
                    <TitleHeading>{audio.title}</TitleHeading>
                    <ReactPlayer
                        url={audio.audio_url}
                        controls={true}
                        width="100%"
                        height="50px"
                        light={true}
                        onPlay={() => onPlay(index)} // Add onPlay event handler
                    />
                    {/* Show story text only for the audio that is being played */}
                    <StoryButton onClick={() => toggleOpen(index)}>
                        {openIndexes.includes(index) ? t('hideStory') : t('showStory')}
                    </StoryButton>
                    <StoryText open={openIndexes.includes(index)}>
                        {audio.story}
                    </StoryText>
                </AudioContainer>
            ))}
            {/* Affichez "Loading..." uniquement si endOfData est faux */}
            {!endOfData && <div ref={loadingRef}>Loading...</div>}
        </MainContainer>
    );
};

export default AudioStories;
