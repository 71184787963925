import React, { Suspense } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './i18n';
import ReactDOM from 'react-dom';
import App from './App';

const clientId = process.env.REACT_APP_CLIENT_ID || '606303163032-qihpuc49c8habhivuh4tf06olpq6t1qf.apps.googleusercontent.com';
const root = document.getElementById('root');
if (root !== null) {
    ReactDOM.render(
        <GoogleOAuthProvider clientId={clientId}>
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </GoogleOAuthProvider>,
        root
    );
}

