import React, {useState, useEffect, useContext, ButtonHTMLAttributes} from "react";
import axiosInstance from './Axios';
import jwtDecode from 'jwt-decode';
import ReactPlayer from "react-player";
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";
import {AxiosError} from "axios";
import {ShakeButtonContext} from "./ShakeButtonContext";
import {
    Card,
    CardContainer,
    AudioPlayerContainer,
    StyledAudioReactPlayer,
    PlayerContainer,
    StyledReactPlayer,
    PlayerWrapper, AudioPlayerWrapper
} from './CardStyles';
import MicIcon from '@mui/icons-material/Mic';
import {CircularProgress} from "@mui/material";
import {TitleHeading, VideoCard} from "./TextToMedia";
import {checkDisableButton, incrementCredit} from "./localStorageUtils";
const StoryContainer = styled.div`
    margin-top: 20px;
    padding-bottom: 50px;  // Ajoutez ceci
    display: block;
    flex-direction: column;  
    align-items: center; 
    justify-content: center;
    width: 100%;  

    @media (max-width: 600px) {
        align-items: center;
    }

    @media (min-width: 1024px) { 
        align-items: center;
    }
`;

const StoryPrompt = styled.span`
    font-size: 20px;
    font-weight: bold;
    color: #4a148c;
    font-family: 'Comic Neue', cursive, sans-serif;

    @media (max-width: 600px) {
        margin-bottom: 10px;
        font-size: 16px; // Reduce font-size for mobile
    }
`;

const StoryInput = styled.textarea`
    flex-grow: 1; 
    margin: 10px 10px;  
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid #4a148c;
    font-family: 'Comic Neue', cursive, sans-serif;
    width: 80%;

    @media (max-width: 600px) {
        width: 90%;
        margin: 0 0 10px 5px;
        font-size: 16px; // Reduce font-size for mobile
    }
`;

const StoryButton = styled.button`
    font-size: 18px;
    padding: 10px 20px;
    background-color: #4a148c;
    color: white;
    border: none;
    border-radius: 10px;
    font-family: 'Comic Neue', cursive, sans-serif;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #e64a19;
    }

    @media (max-width: 600px) {
        width: 100%;
    }
    
    &:disabled {
        background-color: #757575;  // grey background when disabled
        cursor: not-allowed;  // change cursor when disabled
    }
`;

const ResultContainer = styled.div`
    margin-top: 5px;
`;

const ResultHeading = styled.h2`
    font-size: 24px;
    color: #4a148c;  // purple
    font-family: 'Comic Neue', cursive, sans-serif;
`;

const StorySolution = styled.div`
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    max-height: 200px;
    overflow: auto;
    font-size: 20px;
    color: #4a148c;
    font-family: 'Comic Neue', cursive, sans-serif;
    max-width: 100%; 
    white-space: pre-wrap;

    @media (max-width: 600px) {
        width: 100%; // Changer à 100%
    }
`;

const SolutionContainer = styled.div`
    max-height: 100px;
    overflow: auto;
    padding-top: 10px;
    @media (max-width: 600px) {
        max-height: 50vh; // Adjust the max-height for mobile devices
        padding-top: 5px;
    }
`;

const SolutionContainerWait = styled.div`
    max-height: 50px;
    overflow: auto;

    padding-top: 5px;
    @media (max-width: 600px) {
        max-height: 25vh; // Adjust the max-height for mobile devices
        padding-top: 3px;
    }
`;

const StoryButtonWait = styled.button`
    font-size: 10px;
    padding: 2px 4px;
    background-color: #4a148c;
    color: white;
    border: none;
    border-radius: 10px;
    font-family: 'Comic Neue', cursive, sans-serif;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #e64a19;
    }

    @media (max-width: 600px) {
        width: 100%;
    }
`;

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    recording: boolean;
}
const BlinkingMicIcon = styled(MicIcon)`
  animation: blinkingEffect 1s infinite;
  font-size: 30px;  // font size of the mic icon
  color: white;  // initial color of the mic icon

  @keyframes blinkingEffect {
    0% { color: red; }
    50% { color: transparent; }
    100% { color: red; }
  }
`;

const StyledButton = styled.button<ButtonProps>`
  display: ${props => props.recording ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  font-size: 18px;
  padding: 5px 5px;
  background-color: #4a148c;  // purple background
  color: white;  // white text
  border: none;
  border-radius: 10px;  // rounded border
  font-family: 'Comic Neue', cursive, sans-serif;
  cursor: pointer;  // change cursor on hover
  transition: background-color 0.3s;  // smooth color transition

  &:hover {
    background-color: #311b92;  // darker purple
  }
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
`;

type JWTDeCode  = {
    sub: string,
    email: string,
    iat: number,
    exp: number
}

type MediaResult = {
    title: string;
    audio_url?: string;
    video_url?: string;
    image_url?: string;
    story?: string;
    solution?: string;
};

const LoadingContainerWait = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: #FFD700;
  border-radius: 20px;
  padding: 10px;
  margin: 0 auto;
`;

const LoadingText = styled.p`
  font-family: 'Comic Neue', cursive, sans-serif;
  font-size: 14px;
  color: #151516;
  text-align: center;
  margin-top: 5px;
`;

type TextToMediaProps = {
    mediaType: 'audio' | 'video';
    kind: 'story' | 'riddle' | 'joke';
}

type Audio = {
    audio_url: string,
    title: string,
    solution?: string
}

type Video = {
    video_url: string,
    image_url: string,
    title: string
}

const SurpriseMeToMedia: React.FC<TextToMediaProps> = ({mediaType, kind}) => {
    const [executionArn, setExecutionArn] = useState<string | null>(null);
    const [status, setStatus] = useState<string | null>(null);
    const [result, setResult] = useState<MediaResult | null>(null);
    const [recording, setRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
    const [text, setText] = useState<string>("");
    const [showSolution, setShowSolution] = useState(false);
    const [showSolutionWait, setShowSolutionWait] = useState<Record<number, boolean>>({});
    const { shakeButton } = useContext(ShakeButtonContext);

    const [audios, setAudios] = useState<Audio[]>([]);
    const [loadingAudios, setLoadingAudios] = useState<boolean>(false);
    const [loadingResult, setLoadingResult] = useState(false);
    const [videos, setVideos] = useState<Video[]>([]);
    const [buttonDisable, setButtonDisable] = useState<boolean>(false);

    const { t } = useTranslation();

    const fetchStories = async (user_id:string, token:string) => {
        try {
            let defaultLang = localStorage.getItem('language');
            const response = await axiosInstance.get('/audio-stories-wait/' + user_id, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    fileType: mediaType,
                    kind: kind,
                    language: defaultLang
                }
            });
            if (mediaType == "video") {
                setVideos(prevAudios => prevAudios.concat(response.data))
            } else {
                setAudios(prevAudios => prevAudios.concat(response.data));
            }

        } catch (error) {
            console.error("Error calling API Gateway:", error);
        } finally {
            // Set loadingAudios to false when done fetching
            setLoadingAudios(false);
        }
    };


    const submitText = async () => {
        console.log('loadingResult : ', audios);
        setResult(null);
        setExecutionArn(null);
        setShowSolution(false);
        const token = localStorage.getItem('token');
        if (token === null) {
            console.error("No token found in localStorage.");
            shakeButton();
            return;
        }
        console.log('loadingResult1 : ', audios);

        const decodedToken : JWTDeCode = jwtDecode(token);
        const user_id = decodedToken.sub;

        let defaultLang = localStorage.getItem('language');
        let age = localStorage.getItem('age');
        const requestBody = {
            requirement: text,
            fileType: mediaType,
            kind: kind,
            defaultLang: defaultLang,
            age: age
        };

        try {
            const response = await axiosInstance.post( "/text-media/" + user_id, requestBody, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setExecutionArn(response.data);
        } catch (error) {
            if ((error as AxiosError).response?.status === 401) {
                // Tentez de rafraîchir le token ici, puis refaites la requête

            }
            console.error("Error calling API Gateway:", error);
            // Set loadingAudios to false when got the result
            setLoadingAudios(false);
            setLoadingResult(false);
            setButtonDisable(false);
            return;
        }
        console.log('loadingResult2 : ', audios);
        setLoadingResult(true);

        // Set loadingAudios to true when start fetching audios
        setLoadingAudios(true);
        setButtonDisable(true);
        console.log('loadingResult3 : ', audios);
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token === null) {
            console.error("No token found in localStorage.");
            shakeButton();
            return;
        }

        const decodedToken : JWTDeCode = jwtDecode(token);
        const user_id = decodedToken.sub;

        fetchStories(user_id, token);
    }, []); // Empty dependency array means this effect runs once on mount


    useEffect(() => {
        console.log('loadingResultu : ', audios);
        if (!executionArn) return;

        const token = localStorage.getItem('token');
        if (token === null) {
            console.error("No token found in localStorage.");
            shakeButton();
            return;
        }

        let cron = 61000;
        if (mediaType === "audio") {
            cron = 15000;
            if (kind === "riddle" || kind === "joke") {
                cron = 5000;
            }
        }
        const intervalId = setInterval(() => {
            // Reset audios list before making the request
            axiosInstance
                .get('/text-video-check/' + executionArn, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        fileType: mediaType
                    },
                })
                .then(response => {
                    const data = response.data;
                    setStatus(data.status);
                    if (data.status === 'SUCCEEDED') {
                        setResult(data.output.result);
                        clearInterval(intervalId);
                        // Set loadingAudios to false when got the result
                        setLoadingAudios(false);
                        setLoadingResult(false);
                        setButtonDisable(false);
                        incrementCredit(kind, mediaType);
                    } else if (data.status === 'FAILED') {
                        console.error('Error: Execution failed');
                        clearInterval(intervalId);
                        // Set loadingAudios to false when got the result
                        setLoadingAudios(false);
                        setLoadingResult(false);
                        setButtonDisable(false);
                    }
                })
                .catch(error => {
                    console.error('Error checking status:', error);
                    clearInterval(intervalId);
                    setButtonDisable(false);
                });
        }, cron);

        return () => clearInterval(intervalId); // clean up the interval on unmount
    }, [executionArn]);

    return (
        <div>
            <StoryContainer>
                <CardContainer>
                        <StoryButton onClick={submitText} disabled={buttonDisable || checkDisableButton(kind, mediaType)}>{t('send')}</StoryButton>
                </CardContainer>
                {result && (
                <ResultContainer>
                    <CardContainer>
                        <Card>
                            {kind !== 'riddle' && `${t('title')} ${result.title}`}
                        {mediaType === 'audio' ?
                            <AudioPlayerContainer>
                                <AudioPlayerWrapper>
                                    <StyledAudioReactPlayer
                                        url={result!.audio_url}
                                        controls={true}
                                    />
                                </AudioPlayerWrapper>
                            </AudioPlayerContainer> :
                            <PlayerContainer>
                                <PlayerWrapper>
                                    <StyledReactPlayer
                                        url={result!.video_url}
                                        light={result!.image_url}
                                        controls={true}
                                        config={{
                                            file: {
                                                attributes: {
                                                    preload: 'auto',
                                                },
                                            },
                                        }}
                                    />
                                </PlayerWrapper>
                            </PlayerContainer>}
                        {kind === 'riddle' &&
                            <>
                                <SolutionContainer>
                                    {showSolution && <StorySolution>{result.solution}</StorySolution>}
                                <StoryButton onClick={() => setShowSolution(!showSolution)}>{showSolution ? t('hideSolution') : t('showSolution')}</StoryButton>
                                </SolutionContainer>
                            </>
                        }
                    </Card>
                    </CardContainer>
                </ResultContainer>
                )}
                {(loadingResult || loadingAudios) &&
                    <LoadingContainerWait>
                        <CircularProgress />
                        <LoadingText>
                            {`${t('storyInPreparation')} ${
                                kind === 'story'
                                    ? mediaType === "audio"
                                        ? t('listenAnotherStory')
                                        : t('watchAnotherStory')
                                    : kind === 'joke' ? t('giggleWithAnotherJoke') : t('playARiddle')
                            } ${t('pending')} ?`}
                        </LoadingText>
                    </LoadingContainerWait>
                }

                {(loadingResult || loadingAudios) && audios.filter(audio => audio && audio.audio_url).map((audio, index) => (
                    <AudioPlayerContainer key={index}>
                        <AudioPlayerWrapper>
                            <StyledAudioReactPlayer
                                url={audio.audio_url}
                                controls={true}
                                light={true}
                            />
                        </AudioPlayerWrapper>
                        {kind === 'riddle' &&
                            <>
                                <SolutionContainerWait>
                                    {showSolutionWait[index] && <StorySolution>{audio.solution}</StorySolution>}
                                    <StoryButtonWait onClick={() => setShowSolutionWait(prev => ({...prev, [index]: !prev[index]}))}>{showSolutionWait[index] ? t('hideSolution') : t('showSolution')}</StoryButtonWait>
                                </SolutionContainerWait>
                            </>
                        }
                    </AudioPlayerContainer>
                ))}
                {(loadingResult || loadingAudios) && videos.filter(video => video && video.video_url).map((video, index) => (
                    <VideoCard key={index}>
                        <TitleHeading>{video.title}</TitleHeading>
                        <PlayerWrapper>
                            <StyledReactPlayer
                                url={video.video_url}
                                light={video.image_url}
                                controls={true}
                                width="100%"
                                height="100%"
                                config={{
                                    file: {
                                        attributes: {
                                            preload: 'auto',
                                        },
                                    },
                                }}
                            />
                        </PlayerWrapper>
                    </VideoCard>
                ))}
            </StoryContainer>
        </div>
    );
};

export default SurpriseMeToMedia;
