import styled from "@emotion/styled";
import ReactPlayer from "react-player";

export const CardContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const Card = styled.div`
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
`;

export const StyledReactPlayer = styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important; // Important is used here because ReactPlayer sets width and height directly
    height: 100% !important;
`;

export const StyledAudioReactPlayer = styled(StyledReactPlayer)`
    height: 50px
    width: 100% !important;
`;

export const PlayerContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 400px;  /* Ajoutez une hauteur minimale */
    max-height: 800px;  /* Ajoutez une hauteur maximale */
`;

export const AudioPlayerContainer = styled(PlayerContainer)`
    min-height: 40px;  /* Ajoutez une hauteur minimale */
    max-height: 120px;  /* Ajoutez une hauteur maximale */
    
`;

export const PlayerWrapper = styled.div`
    position: relative;
    width: 70vw; 
    height: 50vh; 
    border-radius: 30px;
    overflow: hidden;

    @media (max-width: 600px) {
        width: 90vw; // Ajuster la largeur pour les mobiles ici
        height: 30vh; // Ajuster la hauteur pour les mobiles ici
    }
`;

export const AudioPlayerWrapper = styled(PlayerWrapper)`
    height: 80px; // Ajuster la hauteur pour l'audio ici
    @media (max-width: 600px) {
        width: 90vw; // Ajuster la largeur pour les mobiles ici
        height: 10vh; // Ajuster la hauteur pour les mobiles ici
    }
`;
